import React from 'react'
import styled from 'styled-components'

import CustomizedReport from 'pared/components/CustomizedReport'
import COLORS from 'pared/constants/colors'
import { MOBILE_WIDTH } from 'pared/constants/styles'
import { getBrandSettings } from 'pared/customer'
import {
  TYPE_LAST_WEEK,
  TYPE_THIS_WEEK,
  TYPE_WEEK,
} from 'pared/data/getDateRanges'
import useGetDateRange from 'pared/hooks/useGetDateRange'

interface IProps {
  locationGroupId: number | undefined
  groupByType: string
}

const WingitnorthCustomFlash = ({ locationGroupId, groupByType }: IProps) => {
  const dateRange = useGetDateRange()
  const { brandLocationGroupId } = getBrandSettings()
  const inputParams = {
    startDate: dateRange?.startDateStr,
    endDate: dateRange?.endDateStr,
    locationGroupId: locationGroupId || brandLocationGroupId,
    locationGroupType: groupByType,
  }

  if (!dateRange) {
    return <PageStatusDiv>Loading ...</PageStatusDiv>
  }

  const reportName =
    groupByType !== 'store' ? 'WEEKLY_FLASH_LOCATION_GROUP' : 'WEEKLY_FLASH'

  return (
    <Container>
      {[TYPE_LAST_WEEK, TYPE_THIS_WEEK, TYPE_WEEK].includes(dateRange.type) ? (
        <CustomizedReport
          reportName={reportName}
          inputParams={inputParams}
          csvFileName={`weekly-flash-${inputParams.startDate}-${inputParams.endDate}`}
        />
      ) : (
        <PageStatusDiv>Must select a week to see report</PageStatusDiv>
      )}
    </Container>
  )
}

const Container = styled.div`
  margin: 0;
  overflow: scroll hidden;
`

const PageStatusDiv = styled.div`
  font-family: Lexend-Regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  color: ${COLORS.Steel};

  @media ${MOBILE_WIDTH} {
    padding: 10px;
  }
`

export default React.memo(WingitnorthCustomFlash)
