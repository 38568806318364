import moment from 'moment'
import { useMemo } from 'react'

import { useDateFilter } from '../../../dateFilter'
import { IApiType } from '../../types'
import buildItemMetricTrendsHook from '../utils/buildItemMetricTrendsHook'

export const itemTrendConfigs = {
  itemName: 'string',
  displayParentCategoryName: 'string',
  quantitySold: 'number',
  totalSales: 'price',
  checkCount: 'number',
  checkTotalSales: 'price',
  checkAverage: 'price',
  percentOfChecks: 'percent',
  checkItemCount: 'number',
  avgCheckItemCount: 'number',
  avgCheckAdditionalItemCount: 'number',
  averageUnitPrice: 'price',
  dateRangeLabel: 'string',
} as const

const useItemTrend = (): IApiType => {
  const metricCodes = [
    'quantity_sold',
    'total_sales',
    'check_count',
    'check_total_sales',
    'check_average',
    'check_item_count',
    'average_unit_price',
  ]

  const useTrendData = buildItemMetricTrendsHook((nodes) => nodes)
  const dateFilter = useDateFilter()
  const { data: trendData, loading: trendLoading } = useTrendData({
    metrics: metricCodes,
    startDate: dateFilter.startDate ?? '',
    endDate: dateFilter.endDate ?? '',
  })

  const loading = trendLoading

  return {
    data: useMemo(() => {
      const mappedData = trendData?.reduce((acc, cur) => {
        if (!cur.endDate) return acc
        if (!acc[cur.endDate]) {
          acc[cur.endDate] = { ...cur }
        } else {
          acc[cur.endDate].quantitySold =
            Number(acc[cur.endDate].quantitySold) + Number(cur.quantitySold)
          acc[cur.endDate].totalSales =
            Number(acc[cur.endDate].totalSales) + Number(cur.totalSales)
        }

        return acc
      }, {} as { [dateRangeLabel: string]: { [key: string]: unknown } })

      return Object.values(mappedData ?? {})?.map((data) => ({
        ...data,
        dateRangeLabel: `${moment
          .utc(data.startDate as string)
          .format('M/D/YY')} - ${moment
          .utc(data.endDate as string)
          .format('M/D/YY')}`,
        id: data.endDate as string,
        parentId: 'root',
      }))
    }, [trendData]),
    loading,
  }
}

export default useItemTrend
