import { useMemo } from 'react'

import { IApiDataType } from '../types'
import useFwWingstopCorporateGroupFilter from './useFwWingstopCorporateFilter'

const useFwWingstopCorporateWithoutBreakdownFilter = () => {
  const { data, loading } = useFwWingstopCorporateGroupFilter()

  return {
    data: useMemo((): IApiDataType => {
      if (!data) return null

      const { values, defaultValue } = data

      return {
        values: values.filter(
          (v) => !/Breakdown By/.test(v.label) && !/Breakdown/.test(v.parentId),
        ),
        defaultValue,
      }
    }, [data]),
    loading,
  }
}

export default useFwWingstopCorporateWithoutBreakdownFilter
