import { gql, useQuery } from '@apollo/client'
import orderBy from 'lodash/orderBy'
import moment from 'moment'
import { useMemo } from 'react'

import { DATE_DATABASE_FORMAT } from 'pared/constants'

import { useVariables } from '../../../variables'

const QUERY_TREND_PMIX_DATA = gql`
  query ListFarwestWeekTrendData(
    $iEndDate: Date!
    $iGroupBy: String!
    $iQueryType: String!
    $iFilter: JSON!
  ) {
    trendItemMetricValuesByDateRangeNumber(
      iEndDate: $iEndDate
      iGroupBy: $iGroupBy
      iQueryType: $iQueryType
      iFilter: $iFilter
      iDateRangeNumber: 4
    ) {
      nodes {
        itemId
        itemCode
        itemName
        displayParentCategoryName
        displayParentCategoryCode
        businessYear
        businessQuarter
        businessMonth
        businessWeek
        businessWeekOfMonth
        startDate
        endDate
        dateIndex
        metricData
        metricSummaryData
      }
    }
  }
`

const useTrendUnifiedData = (options: any) => {
  const { variables } = useVariables()
  const dateType = variables.date?.value.type
  const iGroupBy = ((dateType?: string) => {
    switch (dateType) {
      case 'week':
      case 'this_week':
        return 'last_x_weeks'

      case 'period':
        return 'last_x_periods'

      case 'quarter':
        return 'last_x_quarters'

      case 'year':
        return 'last_x_years'

      default:
        return 'last_x_days'
    }
  })(dateType)

  const momentEndDate = moment.utc(
    options.variables.iEndDate,
    DATE_DATABASE_FORMAT,
    true,
  )

  const { data, loading } = useQuery(QUERY_TREND_PMIX_DATA, {
    ...options,
    variables: {
      ...options.variables,
      iGroupBy,
    },
  })

  return {
    data: useMemo(() => {
      const currentWeekEndDate = momentEndDate.format(DATE_DATABASE_FORMAT)

      const unifiedEndpointData =
        data?.trendItemMetricValuesByDateRangeNumber?.nodes

      if (!unifiedEndpointData) return null

      const idToSourceDataMap: { [id: number]: any } = {}
      const otherCatogeryData: { [key: string]: number } = {}
      const summary: { [key: string]: number } = {}

      unifiedEndpointData.forEach((itemData: any) => {
        const weekStr = `Week${itemData.dateIndex}InPast`

        let existingData: any = idToSourceDataMap[itemData.itemId]
        if (!existingData) {
          existingData = {
            ...itemData,
            categoryName: itemData.itemName,
            categoryCode: itemData.itemCode,
            currentWeekEndDate,
          }
        }

        // Hide `Other` category
        if (itemData.itemCode.includes('Other')) {
          for (const key in itemData.metricData) {
            if (itemData.metricData.hasOwnProperty(key)) {
              let newKey = key.replace(/_(.)/g, (_, char) => char.toUpperCase())
              newKey = `${newKey}${weekStr}`

              switch (itemData.metricData[key].unit) {
                case 'DOLLAR':
                case 'PERCENTAGE':
                  if (!otherCatogeryData.hasOwnProperty(newKey)) {
                    otherCatogeryData[newKey] =
                      itemData.metricData[key].value * 100
                  } else {
                    otherCatogeryData[newKey] =
                      otherCatogeryData[newKey] +
                      itemData.metricData[key].value * 100
                  }
                  break

                default:
                  if (!otherCatogeryData.hasOwnProperty(newKey)) {
                    otherCatogeryData[newKey] = itemData.metricData[key].value
                  } else {
                    otherCatogeryData[newKey] =
                      otherCatogeryData[newKey] + itemData.metricData[key].value
                  }
                  break
              }
            }
          }
        } else {
          for (const key in itemData.metricData) {
            if (itemData.metricData.hasOwnProperty(key)) {
              let newKey = key.replace(/_(.)/g, (_, char) => char.toUpperCase())
              newKey = `${newKey}${weekStr}`

              switch (itemData.metricData[key].unit) {
                case 'DOLLAR':
                case 'PERCENTAGE':
                  existingData[newKey] = itemData.metricData[key].value * 100
                  break

                default:
                  existingData[newKey] = itemData.metricData[key].value
                  break
              }
            }
          }

          // we won't show the data if only has location_total_sales
          const metrics = Object.keys(itemData.metricData)
          if (
            metrics.includes('quantity_sold') ||
            metrics.includes('total_sales')
          )
            idToSourceDataMap[itemData.itemId] = existingData
        }

        if (itemData.metricSummaryData) {
          const metricSummaryData = itemData.metricSummaryData

          for (const key in metricSummaryData) {
            if (metricSummaryData.hasOwnProperty(key)) {
              let newKey = key.replace(/_(.)/g, (_, char) => char.toUpperCase())
              newKey = `${newKey}${weekStr}`

              switch (metricSummaryData[key].unit) {
                case 'DOLLAR':
                case 'PERCENTAGE':
                  summary[newKey] = metricSummaryData[key].value * 100
                  break

                default:
                  summary[newKey] = metricSummaryData[key].value
                  break
              }
            }
          }
        }
      })

      //summary need to remove `other` category part
      const filteredSummary: { [key: string]: number } = {}

      for (const key in summary) {
        if (otherCatogeryData.hasOwnProperty(key)) {
          filteredSummary[key] = summary[key] - otherCatogeryData[key]
        } else {
          filteredSummary[key] = summary[key]
        }
      }

      return {
        source: orderBy(Object.values(idToSourceDataMap), [
          'categoryName',
          'subcategoryName',
          'itemName',
        ]),
        summary: {
          ...filteredSummary,
          currentWeekEndDate,
          categoryName: 'TOTAL',
        },
      }
    }, [data]),
    loading,
  }
}

export default useTrendUnifiedData
