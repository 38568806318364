import { gql } from '@apollo/client'
import { useQuery } from '@apollo/client'
import _ from 'lodash'
import { useEffect } from 'react'
import { useState } from 'react'

import { INavParams } from 'pared/Routes/navParams'
import { track } from 'pared/analytics/rankings'
import { feature, page } from 'pared/analytics/user'
import useGroupSelector from 'pared/components/GroupSelector/hooks/useGroupSelector'
import { RANKINGS_ROW_TYPE } from 'pared/constants'
import { BRAND_CODES, BRAND_LOCATION_GROUP_ID } from 'pared/constants/brands'
import { RAW_LIST_LOCATION_AVG_HOURLY_RATES } from 'pared/constants/raw_data/avgHourlyRates'
import {
  RAW_LIST_LOCATION_GROUP_PRODUCTIVITY_COEFFICIENT_DATA,
  RAW_LIST_LOCATION_PRODUCTIVITY_COEFFICIENT_DATA,
} from 'pared/constants/raw_data/productivityCoefficient'
import { getBrandSettings } from 'pared/customer'
import getDateRanges, {
  IDateRange,
  TYPE_PERIOD,
  TYPE_QUARTER,
  TYPE_YEAR,
} from 'pared/data/getDateRanges'
import getDirectors, { IDirector } from 'pared/data/getDirectors'
import getLocations, { ILocation } from 'pared/data/getLocations'
import { scrollToTop } from 'pared/utils/web'

import Main from './Main'
import {
  METRICS_WITH_AVG,
  SORTABLE_METRICS,
  SORTABLE_METRICS_ASC,
  SORTABLE_METRICS_DEFAULT_SORTING_COLUMNS,
} from './constants'
import { getRankingsGql } from './gql'
import useRankingConfig from './hooks/useRankingConfig'
import {
  getCustomizedReportName,
  getQueryAvgFieldName,
  getQueryFilterByQueryName,
  getQueryName,
} from './queryName'

export interface ILocationData {
  location: {
    id: number
    code: string
    name: string
    displayName: string
  }
}

export interface IOrderBy {
  columnName: string
  isAscending: boolean
}

interface IProps {
  navParams: INavParams
}

interface IPageFilter {
  rankBy: string
  groupBy: string
}

const defaultPageFilter: IPageFilter = {
  rankBy: 'scorecard',
  groupBy: 'store',
}

const initialFilter: { [key: string]: IPageFilter } = {
  papajohns: {
    rankBy: 'net_sales',
    groupBy: 'store',
  },
  wingitnorth: {
    rankBy: 'net_sales',
    groupBy: 'store',
  },
  wingstop: {
    rankBy: 'net_sales',
    groupBy: 'store',
  },
  irmg_bk: {
    rankBy: 'net_sales',
    groupBy: 'store',
  },
  viking_bk: {
    rankBy: 'net_sales',
    groupBy: 'store',
  },
  jsc_bk: {
    rankBy: 'net_sales',
    groupBy: 'store',
  },
  jsc_bk_nso: {
    rankBy: 'net_sales',
    groupBy: 'store',
  },
  multi_king_bk: {
    rankBy: 'net_sales',
    groupBy: 'store',
  },
  supreme_bk: {
    rankBy: 'net_sales',
    groupBy: 'store',
  },
  timoney_bk: {
    rankBy: 'net_sales',
    groupBy: 'store',
  },
  laird_bk: {
    rankBy: 'net_sales',
    groupBy: 'store',
  },
  mbn_bk: {
    rankBy: 'net_sales',
    groupBy: 'store',
  },
  bmc_bk: {
    rankBy: 'net_sales',
    groupBy: 'store',
  },
  demo_bk: {
    rankBy: 'net_sales',
    groupBy: 'store',
  },
  del_rio_bk: {
    rankBy: 'net_sales',
    groupBy: 'store',
  },
  irmg_pop: {
    rankBy: 'net_sales',
    groupBy: 'store',
  },
  syed_pop: {
    rankBy: 'net_sales',
    groupBy: 'store',
  },
  demo_pop: {
    rankBy: 'net_sales',
    groupBy: 'store',
  },
  cava: {
    rankBy: 'net_sales',
    groupBy: 'store',
  },
  supreme_pop: {
    rankBy: 'net_sales',
    groupBy: 'store',
  },
  ghai_pop: {
    rankBy: 'net_sales',
    groupBy: 'store',
  },
  mwb: {
    rankBy: 'net_sales',
    groupBy: 'store',
  },
  burgerworks: {
    rankBy: 'net_sales',
    groupBy: 'store',
  },
  ace_pop: {
    rankBy: 'net_sales',
    groupBy: 'store',
  },
}

const persistentPageFilters: { [key: string]: IPageFilter } =
  BRAND_CODES.reduce((prev, cur) => {
    if (prev[cur]) {
      return prev
    }

    switch (cur) {
      case 'jitb':
        return {
          ...prev,
          [cur]: {
            rankBy: 'net_sales',
            groupBy: 'store',
          },
        }

      // Local Favorite
      case 'lfr_ef':
      case 'lfr_tr':
      case 'lfr_sn':
      case 'lfr_lv':
      case 'lfr_vb':
      case 'lfr_tu':
      case 'lfr_ws':
      case 'lfr_jn':
      case 'lfr_mm':
      case 'lfr_ol':
      case 'lfr_dt':
      case 'lfr_uv':
      case 'lfr_bth':
        return {
          ...prev,
          [cur]: {
            rankBy: 'gross_sales',
            groupBy: 'store',
          },
        }

      case 'bibibop':
        return {
          ...prev,
          [cur]: {
            rankBy: 'net_sales',
            groupBy: 'store',
          },
        }

      default:
        return {
          ...prev,
          [cur]: defaultPageFilter,
        }
    }
  }, initialFilter)

const Rankings = ({ navParams }: IProps) => {
  const dateRange = navParams.dateRange || ''
  const { brand, brandId, brandLocationGroupId } = getBrandSettings()
  const rankingConfig = useRankingConfig(brand)

  const [selectedDateRange, setSelectedDateRange] = useState<IDateRange | null>(
    null,
  )
  const isPeriod = selectedDateRange
    ? [TYPE_PERIOD, TYPE_QUARTER, TYPE_YEAR].includes(selectedDateRange.type)
    : true
  const [rankBy, setRankBy] = useState<string>(
    persistentPageFilters[brand].rankBy,
  )

  const { groupBy, groupByType, setGroupBy, options, locationGroupId } =
    useGroupSelector({ initialGroupBy: persistentPageFilters[brand].groupBy })

  const [allLocations, setAllLocations] = useState<ILocation[] | null>([])
  const [allDirectors, setAllDirectors] = useState<IDirector[] | null>([])
  const [isRankingsDataEmpty, setIsRankingsDataEmpty] = useState<
    boolean | null
  >(false)
  const [orderBy, setOrderBy] = useState<IOrderBy>({
    columnName: '',
    isAscending: false,
  })

  const rankingsGql = getRankingsGql(rankBy, groupByType)
  const queryName = getQueryName(rankBy, groupByType) || ''

  // temporarily hard code this
  let rankingFromLocationGroupIds: number[] | null = null
  let rankingFromLocationGroupTypes: string[] | null = null
  if (allDirectors) {
    if (groupByType === 'store') {
      rankingFromLocationGroupIds = [
        parseInt(locationGroupId) || BRAND_LOCATION_GROUP_ID[brand],
      ]
    } else if (groupByType === 'director') {
      rankingFromLocationGroupIds = allDirectors.map((director) => {
        return director.locationGroupId
      })
    } else {
      rankingFromLocationGroupTypes = [groupByType]
    }
  }

  const rankingKpis = (() => {
    if (isPeriod) {
      if (
        rankBy === 'cogs' &&
        !!rankingConfig.find(({ key }) => key === 'cogs')?.kpiKey
      )
        return 'period_cogs'

      if (
        rankBy === 'cogs_budget_variance' &&
        !!rankingConfig.find(({ key }) => key === 'cogs_budget_variance')
          ?.kpiKey
      )
        return 'period_cogs_budget_variance'
    }
    return rankBy
  })()
  const iFilter = getQueryFilterByQueryName(queryName, {
    location_group_ids: rankingFromLocationGroupIds,
    ranking_from: {
      location_group_ids: rankingFromLocationGroupIds,
    },
    location_group_types: rankingFromLocationGroupTypes,
    brand_ids: [brandId],
    ranking_kpis: [rankingKpis],
  })

  const periodStartDate = _.get(selectedDateRange, 'startDateStr', '')
  const periodEndDate = _.get(selectedDateRange, 'endDateStr', '')

  const customizedReportName = getCustomizedReportName(rankBy, groupByType)
  let customizedReportParams: any = {
    iReportName: customizedReportName,
    iInputParams: {
      startDate: periodStartDate,
      endDate: periodEndDate,
      locationGroupId: brandLocationGroupId,
      brandId,
    },
  }

  if (customizedReportName === 'LIST_LOCATION_STORES_CUSTOMIZED_EXTEND_TABLE') {
    customizedReportParams = {
      iReportName: customizedReportName,
      iInputParams: {
        startDate: periodStartDate,
        endDate: periodEndDate,
        locationGroupIds: rankingFromLocationGroupIds,
      },
    }
  }

  if (
    customizedReportName ===
    'LIST_LOCATION_GROUP_STORES_CUSTOMIZED_EXTEND_TABLE'
  ) {
    customizedReportParams = {
      iReportName: customizedReportName,
      iInputParams: {
        startDate: periodStartDate,
        endDate: periodEndDate,
        locationGroupTypes: rankingFromLocationGroupTypes,
      },
    }
  }

  if (customizedReportName === 'METRIC_VALUES')
    iFilter.metrics = [_.snakeCase(rankBy)]

  const {
    loading: rankingsLoading,
    error: rankingsError,
    data: rankingsData,
  } = useQuery(
    gql`
      ${rankingsGql}
    `,
    {
      skip: !(selectedDateRange && allDirectors),
      variables: {
        iFilter,
        iStartDate: periodStartDate,
        iEndDate: periodEndDate,
        isBreakdownByLocationGroup: groupByType !== 'store',
        ...customizedReportParams,
      },
    },
  )

  useEffect(() => {
    async function fetchGeneralInfo() {
      const [newAllDateRangeData, directors, locationData] = await Promise.all([
        getDateRanges(),
        getDirectors(),
        getLocations(),
      ])

      if (Array.isArray(directors) && directors.length > 0) {
        setAllDirectors(directors)
      }

      const years = newAllDateRangeData.years

      if (Array.isArray(years) && years.length > 0) {
        let newSelectedDateRange: IDateRange | null = null
        if (dateRange) {
          newSelectedDateRange = newAllDateRangeData.dateRangeMap[dateRange]
        }

        if (!newSelectedDateRange) {
          newSelectedDateRange = newAllDateRangeData.defaultPeriod
        }

        if (newSelectedDateRange) {
          setSelectedDateRange(newSelectedDateRange)
        }
      }

      const locations = locationData.locations
      if (Array.isArray(locations) && locations.length > 0) {
        setAllLocations(locations)
      }
    }

    fetchGeneralInfo()
  }, [dateRange])

  useEffect(() => {
    setIsRankingsDataEmpty(false)

    if (_.includes(SORTABLE_METRICS, rankBy)) {
      const sortableMetric = _.first(
        _.filter(SORTABLE_METRICS_DEFAULT_SORTING_COLUMNS, {
          metricsName: rankBy,
        }),
      )
      const orderByColumnName = _.get(
        sortableMetric,
        'defaultSortingColumn',
        rankBy,
      )
      let orderByIsAscending = false
      if (_.includes(SORTABLE_METRICS_ASC, rankBy)) {
        orderByIsAscending = true
      }

      setOrderBy({
        columnName: orderByColumnName,
        isAscending: orderByIsAscending,
      })
    }
  }, [rankBy])

  useEffect(() => {
    if (navParams.pageUrl) {
      scrollToTop()
      page.visit(navParams.pageUrl)
      feature.used('Rankings')
    }
  }, [navParams.pageUrl])

  useEffect(() => {
    persistentPageFilters[brand].groupBy = groupByType
    track.groupByChanged(groupByType)
  }, [groupByType])

  const getStoreDisplayNameById = (storeId: number) => {
    const store = _.first(_.filter(allLocations, ['id', storeId]))
    const code = _.get(store, 'code', '')
    const name = _.get(store, 'name', '')

    return `${code} - ${name}`
  }

  const getDirectorNameByEmployeeId = (employeeId: number) => {
    const director = _.first(_.filter(allDirectors, ['employeeId', employeeId]))
    const firstName = _.get(director, 'firstName', '')
    const lastName = _.get(director, 'lastName', '')

    return `${firstName} ${lastName}`
  }

  const getDirectorNameByLocationGroupId = (locationGroupId: number) => {
    const director = _.first(
      _.filter(allDirectors, ['locationGroupId', locationGroupId]),
    )
    const firstName = _.get(director, 'firstName', '')
    const lastName = _.get(director, 'lastName', '')

    return `${firstName} ${lastName}`
  }

  const onSelectRankBy = (event: any) => {
    const value = _.get(event, 'target.value', '')
    setRankBy(value)
    persistentPageFilters[brand].rankBy = value
    track.rankByChanged(value)
  }

  let rawRankingsAvgData = {}
  let avgData = ''
  let rawRankingsData = []
  let mockRankingsData = null

  const queryAvgFieldName = getQueryAvgFieldName(rankBy) || ''

  if (
    rankingsData &&
    _.get(rankingsData, [queryName]) &&
    Array.isArray(_.get(rankingsData, [queryName, 'nodes']))
  ) {
    rawRankingsAvgData = _.first(_.get(rankingsData, [queryName, 'nodes'], []))

    avgData = _.get(rawRankingsAvgData, [queryAvgFieldName], 0)
  }

  if (rankBy === 'productivityCoefficient') {
    if (groupByType === 'store')
      mockRankingsData = rankingsData?.rankLocationScorecard.nodes
        .map(({ locationId }) => {
          const { value } =
            RAW_LIST_LOCATION_PRODUCTIVITY_COEFFICIENT_DATA[locationId]

          return {
            locationId,
            productivityCoefficient: value.toFixed(2),
          }
        })
        .sort((a, b) => b.productivityCoefficient - a.productivityCoefficient)
    else if (groupByType === 'director')
      mockRankingsData = rankingsData?.rankLocationGroupScorecard.nodes
        .map(({ locationGroupId, doEmployeeId }) => {
          const { value } =
            RAW_LIST_LOCATION_GROUP_PRODUCTIVITY_COEFFICIENT_DATA[
              locationGroupId
            ]

          return {
            doEmployeeId,
            productivityCoefficient: value.toFixed(2),
          }
        })
        .sort((a, b) => b.productivityCoefficient - a.productivityCoefficient)
  }

  if (rankBy === 'avgHourlyRate') {
    if (groupByType === 'store')
      mockRankingsData = rankingsData?.rankLocationScorecard.nodes
        .map(({ locationId }) => {
          const { avgHourlyRate } =
            RAW_LIST_LOCATION_AVG_HOURLY_RATES[locationId]

          return {
            locationId,
            avgHourlyRate: avgHourlyRate.toFixed(2),
          }
        })
        .sort((a, b) => b.avgHourlyRate - a.avgHourlyRate)
    else if (groupByType === 'director')
      mockRankingsData = rankingsData?.rankLocationGroupScorecard.nodes
        .map(({ locationGroupId, doEmployeeId }) => {
          const { avgHourlyRate } =
            RAW_LIST_LOCATION_AVG_HOURLY_RATES[locationGroupId]

          return {
            doEmployeeId,
            avgHourlyRate: avgHourlyRate.toFixed(2),
          }
        })
        .sort((a, b) => b.avgHourlyRate - a.avgHourlyRate)
  }

  let processedRankingDataEmpty = false
  let metricsColumnName = ''

  const orderRankingsData = (rawRankingsData: any[], rankBy: string) => {
    if (rankBy === 'salesPerLaborHour') {
      metricsColumnName = 'grossSalesPerLaborHour'
    } else if (rankBy === 'ppa') {
      metricsColumnName = 'ppa'
    } else if (rankBy === 'appetizerPpa') {
      metricsColumnName = 'appetizerPpa'
    } else if (rankBy === 'lbwPpa') {
      metricsColumnName = 'lbwPpa'
    } else if (rankBy === 'dessertPpa') {
      metricsColumnName = 'dessertPpa'
    } else if (rankBy === 'deliverySales') {
      metricsColumnName = 'sumSubtotal'
    } else if (rankBy === 'orderWithAnyIssues') {
      metricsColumnName = 'orderWithAnyIssues'
    } else if (rankBy === 'combinedStarRating') {
      metricsColumnName = 'avgCustomerReviewScore'
    }

    if (_.includes(METRICS_WITH_AVG, rankBy)) {
      const avg = {
        rowType: RANKINGS_ROW_TYPE.AVG,
      }

      avg[metricsColumnName] = avgData

      rawRankingsData = _.concat(rawRankingsData, avg)
    }

    rawRankingsData = _.orderBy(rawRankingsData, [metricsColumnName], 'desc')

    return rawRankingsData
  }

  if (
    rankingsData &&
    _.get(rankingsData, [queryName]) &&
    Array.isArray(_.get(rankingsData, [queryName, 'nodes']))
  ) {
    rawRankingsData = _.get(rankingsData, [queryName, 'nodes'], [])
    if (queryName === 'getCustomizedReport') {
      rawRankingsData = rawRankingsData[0].reportResult.tableData.map(
        (data: any) => ({
          locationId: data.locationInfo?.id,
          doEmployeeId: data.directorInfo?.id,
          ...data,
        }),
      )
    }

    if (queryName === 'listLocationMetricValues') {
      rawRankingsData = rawRankingsData
        .map((data: any) =>
          Object.keys(data.metricData).reduce(
            (result, key) => ({
              ...result,
              [_.camelCase(key)]: data.metricData[key].value,
            }),
            {
              locationId: data.locationId,
            },
          ),
        )
        .sort((a, b) => {
          if (_.isNil(a[rankBy])) return 1

          if (_.isNil(b[rankBy])) return -1

          return a[rankBy] - b[rankBy]
        })
    }

    if (queryName === 'listLocationGroupMetricValues') {
      rawRankingsData = rawRankingsData
        .map((data: any) =>
          Object.keys(data.metricData).reduce(
            (result, key) => ({
              ...result,
              [_.camelCase(key)]: data.metricData[key].value,
            }),
            {
              doLocationGroupId: data.locationGroupId,
            },
          ),
        )
        .sort((a, b) => {
          if (_.isNil(a[rankBy])) return 1

          if (_.isNil(b[rankBy])) return -1

          return a[rankBy] - b[rankBy]
        })
    }

    if (queryName === 'listLocationSalesmanshipKpis') {
      const kpiDef = rankingConfig.find(({ key }) => key === rankBy)
      const salesType = kpiDef?.salesType || ''
      const kpiKey = kpiDef?.kpiKey || ''
      rawRankingsData = rawRankingsData.map(
        ({ locationInfo, categorizedSalesDetails, totalSalesDetails }) => {
          let value = 0
          if (salesType === 'total') {
            value = totalSalesDetails[kpiKey]
          } else {
            value = categorizedSalesDetails[salesType]?.[kpiKey]
          }

          return {
            kpi: rankBy,
            locationId: locationInfo.id,
            rankingData: {
              [rankBy]: value,
            },
          }
        },
      )
    }

    if (queryName === 'listLocationGroupSalesmanshipKpis') {
      const kpiDef = rankingConfig.find(({ key }) => key === rankBy)
      const salesType = kpiDef?.salesType || ''
      const kpiKey = kpiDef?.kpiKey || ''
      rawRankingsData = rawRankingsData.map(
        ({
          locationGroupId,
          locationGroupName,
          categorizedSalesDetails,
          totalSalesDetails,
        }) => {
          let value = 0
          if (salesType === 'total') {
            value = totalSalesDetails[kpiKey]
          } else {
            value = categorizedSalesDetails[salesType]?.[kpiKey]
          }

          return {
            kpi: rankBy,
            doLocationGroupId: locationGroupId,
            locationGroupName,
            rankingData: {
              [rankBy]: value,
            },
          }
        },
      )
    }

    if (queryName === 'listLocationSpeedOfService') {
      const kpiDef = rankingConfig.find(({ key }) => key === rankBy)
      const kpiKey = kpiDef?.kpiKey || ''
      rawRankingsData = rawRankingsData.map(
        ({ locationInfo, sosDetails }: any) => {
          const value = sosDetails?.[kpiKey]?.avgSeconds || 0
          return {
            kpi: rankBy,
            locationId: locationInfo.id,
            rankingData: {
              [rankBy]: value,
            },
          }
        },
      )
    }

    if (queryName === 'listLocationGroupSpeedOfService') {
      const kpiDef = rankingConfig.find(({ key }) => key === rankBy)
      const kpiKey = kpiDef?.kpiKey || ''
      rawRankingsData = rawRankingsData.map(
        ({ locationGroupId, locationGroupName, sosDetails }: any) => {
          let value = sosDetails?.[kpiKey]?.avgSeconds || 0

          return {
            kpi: rankBy,
            doLocationGroupId: locationGroupId,
            locationGroupName,
            rankingData: {
              [rankBy]: value,
            },
          }
        },
      )
    }

    if (queryName === 'listLocationEntreesPerLaborHour') {
      rawRankingsData = rawRankingsData.map(({ locationId, details }) => {
        return {
          locationId,
          entreesPerLaborHourAt11: details['11'].entreesPerLaborHour,
          entreesPerLaborHourAt12: details['12'].entreesPerLaborHour,
          entreesPerLaborHourAt13: details['13'].entreesPerLaborHour,
          entreesPerLaborHourAt17: details['17'].entreesPerLaborHour,
          entreesPerLaborHourAt18: details['18'].entreesPerLaborHour,
          entreesPerLaborHourAt19: details['19'].entreesPerLaborHour,
        }
      })
    }

    if (queryName === 'listLocationGroupEntreesPerLaborHour') {
      rawRankingsData = rawRankingsData.map(
        ({ locationGroupId, locationGroupName, details }) => {
          return {
            kpi: rankBy,
            doLocationGroupId: locationGroupId,
            locationGroupName,
            rankingData: {
              entreesPerLaborHourAt11: details['11'].entreesPerLaborHour,
              entreesPerLaborHourAt12: details['12'].entreesPerLaborHour,
              entreesPerLaborHourAt13: details['13'].entreesPerLaborHour,
              entreesPerLaborHourAt17: details['17'].entreesPerLaborHour,
              entreesPerLaborHourAt18: details['18'].entreesPerLaborHour,
              entreesPerLaborHourAt19: details['19'].entreesPerLaborHour,
            },
          }
        },
      )
    }

    if (queryName === 'listLocationUpsizePercent') {
      rawRankingsData = rawRankingsData.map(({ locationId, upsizePercent }) => {
        return {
          locationId,
          upsizePercent,
        }
      })
    }

    if (queryName === 'listLocationGroupUpsizePercent') {
      rawRankingsData = rawRankingsData.map(
        ({ locationGroupId, locationGroupName, upsizePercent }) => {
          return {
            kpi: rankBy,
            doLocationGroupId: locationGroupId,
            locationGroupName,
            rankingData: {
              upsizePercent,
            },
          }
        },
      )
    }

    rawRankingsData = rawRankingsData.map((d) => {
      return {
        ...d,
        ...d.rankingData,
      }
    })

    processedRankingDataEmpty = _.isEmpty(rawRankingsData)

    const isAscending = orderBy.isAscending ? 1 : -1

    if (_.includes(SORTABLE_METRICS, rankBy)) {
      rawRankingsData = _.sortBy(rawRankingsData, (d) => {
        return parseFloat(_.get(d, [orderBy.columnName])) * isAscending
      })
    } else {
      rawRankingsData = orderRankingsData(rawRankingsData, rankBy)
    }
  }

  return (
    <Main
      navParams={navParams}
      isLoading={rankingsLoading}
      errorMessage={_.get(rankingsError, 'message', '')}
      rankBy={rankBy}
      onSelectRankBy={onSelectRankBy}
      groupBy={groupBy}
      groupByType={groupByType}
      onSelectGroupBy={setGroupBy}
      groupByOptions={options}
      rankingsData={mockRankingsData || rawRankingsData}
      getStoreDisplayNameById={getStoreDisplayNameById}
      getDirectorNameByEmployeeId={getDirectorNameByEmployeeId}
      getDirectorNameByLocationGroupId={getDirectorNameByLocationGroupId}
      isRankingsDataEmpty={isRankingsDataEmpty || processedRankingDataEmpty}
      orderBy={orderBy}
      setOrderBy={setOrderBy}
      startDate={periodStartDate}
      endDate={periodEndDate}
    />
  )
}

export default Rankings
