import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import _ from 'lodash'
import { CSVLink } from 'react-csv'
import styled from 'styled-components'

import { INavParams } from 'pared/Routes/navParams'
import Print from 'pared/components/basicUi/Print'
import ExpoSelect from 'pared/components/basicUi/select'
import { IValueType } from 'pared/components/basicUi/select/MenuItem'
import DateRangeSelector from 'pared/components/nav/DateRangeSelector'
import { RANKINGS_ROW_TYPE, SYSTEMWIDE_AVG_TEXT } from 'pared/constants'
import COLORS from 'pared/constants/colors'
import DownArrow from 'pared/images/basic/arrow/caret-down.svg'
import UpArrow from 'pared/images/basic/arrow/caret-up.svg'
import DownloadIcon from 'pared/images/basic/download.png'
import CorporateFssTable from 'pared/pages/CorporateFss/CorporateFssTable'
import { getBrand } from 'pared/utils/brand'
import {
  toDisplayedWaitTime,
  toPercentageString,
  toUsdString,
} from 'pared/utils/number'

import RankBySelect from './RankBySelect'
import {
  COLORABLE_METRICS_NEGATIVE_RED,
  COLORABLE_METRICS_POSITIVE_RED,
  SORTABLE_METRICS_COLUMN_KEYS,
  UNFAVORABLE_VARIANCE_HIDE_NEGATIVE,
  UNFAVORABLE_VARIANCE_HIDE_POSITIVE,
  getRankByDisplayNames,
} from './constants'
import useRankingConfig from './hooks/useRankingConfig'
import { IOrderBy } from './index'
import { getColumnNames } from './queryName'

interface IRankTableTh {
  textAlign?: string
}

interface IRankTableTd {
  bold?: boolean
  textAlign?: string
  danger?: boolean
  isAvg?: boolean
}

interface IProps {
  navParams: INavParams
  isLoading: boolean
  errorMessage: string
  rankBy: string
  onSelectRankBy: (event: any) => void
  groupBy: string[]
  groupByType: string
  onSelectGroupBy: (event: any) => void
  groupByOptions: IValueType[]
  rankingsData: any
  getStoreDisplayNameById: (storeId: number) => string
  getDirectorNameByEmployeeId: (employeeId: number) => string
  getDirectorNameByLocationGroupId: (locationGroupId: number) => string
  isRankingsDataEmpty: boolean
  orderBy: IOrderBy
  setOrderBy: any
  startDate: string
  endDate: string
}

const WebLargeScreenMain = ({
  navParams,
  isLoading,
  errorMessage,
  rankBy,
  onSelectRankBy,
  groupBy,
  groupByType,
  onSelectGroupBy,
  groupByOptions,
  rankingsData,
  getStoreDisplayNameById,
  getDirectorNameByEmployeeId,
  getDirectorNameByLocationGroupId,
  isRankingsDataEmpty,
  orderBy,
  setOrderBy,
  startDate,
  endDate,
}: IProps) => {
  const rankByDisplayNames = getRankByDisplayNames()
  const rankingConfig = useRankingConfig(getBrand())

  const handleOrdering = (event: any) => {
    const orderingColumnValue = _.get(event, 'currentTarget.dataset.value')
    setOrderBy({
      columnName: orderingColumnValue,
      isAscending: !orderBy.isAscending,
    })
  }

  let orderStatus: React.ReactNode
  if (orderBy.isAscending) {
    orderStatus = <UpArrowImg src={UpArrow} alt="ASC"></UpArrowImg>
  } else {
    orderStatus = <DownArrowImg src={DownArrow} alt="DESC"></DownArrowImg>
  }

  const columnNames = getColumnNames(rankBy, groupByType)
  const headers = _.map(columnNames, (n) => {
    const columnKey = _.get(n, 'key', '')
    let header = null

    if (_.includes(SORTABLE_METRICS_COLUMN_KEYS, columnKey)) {
      header = (
        <RankTableSortableTh
          textAlign={
            [
              'net_sales_budget_variance',
              'net_sales_budget_variance_amount',
              'gross_sales_budget_variance',
              'gross_sales_budget_variance_amount',
              'cogs',
              'cogs_percent',
            ].includes(columnKey)
              ? 'center'
              : 'left'
          }
          onClick={handleOrdering}
          data-value={columnKey}
        >
          <span>{_.get(n, 'displayName', '')}</span>
          {orderBy.columnName === columnKey ? orderStatus : null}
        </RankTableSortableTh>
      )
    } else {
      header = (
        <RankTableTh
          textAlign={
            [
              'net_sales',
              'gross_sales',
              'grossSalesPerLaborHour',
              'productivityCoefficient',
              'avgHourlyRate',
            ].includes(columnKey)
              ? 'center'
              : 'left'
          }
        >
          {_.get(n, 'displayName', '')}
        </RankTableTh>
      )
    }

    return header
  })

  let rank = 1

  let displayName = rankByDisplayNames[rankBy]
  const config = rankingConfig.find(({ key }) => key === rankBy)
  if (config?.name) {
    displayName = config.name
  }

  const formattedDisplayName = displayName.replaceAll(' ', '-').toLowerCase()
  const formattedDateRange = `${startDate.replaceAll(
    '-',
    '',
  )}-${endDate.replaceAll('-', '')}`
  const csvFileName = `rankings-${formattedDisplayName}-${formattedDateRange}`
  const csvData = []
  const tableContent = _.map(rankingsData, (d) => {
    const rows: any = []
    const rowType = _.get(d, 'rowType', '')

    if (rowType === RANKINGS_ROW_TYPE.AVG) {
      rows.push(<RankNumberAvgTd bold textAlign={'left'}></RankNumberAvgTd>)
    } else {
      rows.push(
        <RankNumberTd bold textAlign={'left'}>
          {rank}
        </RankNumberTd>,
      )
      rank++
    }

    _.each(columnNames, (columnName) => {
      const columnKey = _.get(columnName, 'key', '')

      let dataPoint = _.get(d, columnKey, '')
      const dataType: string = _.get(columnName, 'type', '') || ''
      let processedDataPoint = dataPoint

      let isDanger = false

      if (_.includes(COLORABLE_METRICS_POSITIVE_RED, columnKey)) {
        isDanger = parseFloat(dataPoint) > 0
      } else if (_.includes(COLORABLE_METRICS_NEGATIVE_RED, columnKey)) {
        isDanger = parseFloat(dataPoint) < 0
      }

      if (dataType === 'percent') {
        processedDataPoint = toPercentageString(dataPoint, 1)
      }

      if (dataType === 'percentTimesOneHundred') {
        if (dataPoint != null) {
          processedDataPoint = toPercentageString(dataPoint / 100.0, 1)
        } else {
          processedDataPoint = '-'
        }
      }

      if (dataType === 'currencyInCents') {
        processedDataPoint = toUsdString(dataPoint / 100.0, 2)
      }

      if (dataType === 'score') {
        if (dataPoint != null) {
          processedDataPoint = dataPoint.toFixed(2)
        } else {
          processedDataPoint = '-'
        }
      }

      if (dataType === 'numberWithOneDecimal') {
        if (dataPoint) {
          processedDataPoint = dataPoint?.toFixed(1) ?? '-'
        } else {
          processedDataPoint = '-'
        }
      }

      if (dataType === 'seconds') {
        if (dataPoint) {
          const minutes = Math.floor(dataPoint / 60)
          const seconds = dataPoint % 60
          processedDataPoint = `${minutes}:${
            seconds < 10 ? `0${seconds}` : seconds
          }`
        } else {
          processedDataPoint = '-'
        }
      }

      if (dataType === 'mins') {
        if (dataPoint) {
          processedDataPoint = toDisplayedWaitTime(dataPoint)
        } else {
          processedDataPoint = '-'
        }
      }

      if (['locationId', 'locationInfo.id'].includes(columnKey)) {
        if (rowType === RANKINGS_ROW_TYPE.AVG) {
          processedDataPoint = SYSTEMWIDE_AVG_TEXT
        } else {
          processedDataPoint = getStoreDisplayNameById(dataPoint)
        }
      }

      if (['doEmployeeId', 'employeeInfo.id'].includes(columnKey)) {
        if (rowType === RANKINGS_ROW_TYPE.AVG) {
          processedDataPoint = SYSTEMWIDE_AVG_TEXT
        } else {
          processedDataPoint = getDirectorNameByEmployeeId(dataPoint)
        }
      }

      if (['doLocationGroupId'].includes(columnKey)) {
        processedDataPoint = getDirectorNameByLocationGroupId(dataPoint)
      }

      if (
        _.includes(UNFAVORABLE_VARIANCE_HIDE_POSITIVE, columnKey) &&
        dataPoint > 0
      ) {
        processedDataPoint = '-'
      }

      if (
        _.includes(UNFAVORABLE_VARIANCE_HIDE_NEGATIVE, columnKey) &&
        dataPoint < 0
      ) {
        processedDataPoint = '-'
      }

      if (columnKey === 'averageTripTime') {
        processedDataPoint = processedDataPoint
          ? (dataPoint / 60).toFixed(1)
          : '-'
      }

      rows.push(
        <RankTableTd
          textAlign={
            [
              'net_sales',
              'net_sales_budget_variance',
              'net_sales_budget_variance_amount',
              'gross_sales',
              'gross_sales_budget_variance',
              'gross_sales_budget_variance_amount',
              'cogs',
              'cogs_percent',
              'grossSalesPerLaborHour',
              'productivityCoefficient',
              'avgHourlyRate',
            ].includes(columnKey)
              ? 'center'
              : 'left'
          }
          danger={isDanger}
          isAvg={rowType === RANKINGS_ROW_TYPE.AVG}
        >
          {processedDataPoint}
        </RankTableTd>,
      )
    })

    csvData.push(rows.map((row: any) => row.props.children))

    if (rowType === RANKINGS_ROW_TYPE.AVG) {
      return <AvgTr>{rows}</AvgTr>
    } else {
      return <tr>{rows}</tr>
    }
  })

  csvData.unshift([
    'Rank',
    ...headers.map((header) => {
      if (Array.isArray(header.props.children)) {
        return header.props.children[0].props.children
      }

      return header.props.children
    }),
  ])

  let rankTable = null

  if (errorMessage) {
    rankTable = (
      <MainContainer>
        <PageStatusDiv>{errorMessage}</PageStatusDiv>
      </MainContainer>
    )
  }

  if (isLoading) {
    rankTable = (
      <MainContainer>
        <PageStatusDiv>Loading ...</PageStatusDiv>
      </MainContainer>
    )
  }

  if (!errorMessage && !isLoading && !_.isEmpty(rankingsData)) {
    rankTable = (
      <>
        <TitleDiv>{displayName}</TitleDiv>
        <VerticalSpacer />
        <StyledCSVLink data={csvData} filename={`${csvFileName}.csv`}>
          <img src={DownloadIcon} height="30px" />
          <div>Download Table as CSV</div>
        </StyledCSVLink>
        <VerticalSpacer />
        <RankTable>
          <thead>
            <tr>
              <RankTableTh textAlign={'left'}>Rank</RankTableTh>
              {headers}
            </tr>
          </thead>
          <tbody>{tableContent}</tbody>
        </RankTable>
      </>
    )
  }

  if (isRankingsDataEmpty) {
    rankTable = (
      <MainContainer>
        <PageStatusDiv>
          There is no data within the date range selected. Please select a
          different date range.
        </PageStatusDiv>
      </MainContainer>
    )
  }

  if (rankBy === 'fss_score') {
    rankTable = <CorporateFssTable />
  }

  return (
    <MainContainer>
      <FlexContainer>
        <TitleDiv>Rankings</TitleDiv>
        <Print>
          <PrintTableHeader>
            Dates: {startDate} to {endDate}
          </PrintTableHeader>
          <PrintContainer>{rankTable}</PrintContainer>
        </Print>
      </FlexContainer>
      <FilterOuterContainer>
        <SelectionContainer>
          <TopFilterByContainer>Rank by</TopFilterByContainer>
          <RankingFilterSelectorContainer>
            <RankingFormControl
              variant="outlined"
              margin="dense"
              style={{ width: 300 }}
            >
              <RankBySelect rankBy={rankBy} onSelectRankBy={onSelectRankBy} />
            </RankingFormControl>
          </RankingFilterSelectorContainer>
        </SelectionContainer>
        <SelectionContainer>
          <TopFilterByContainer>Group by</TopFilterByContainer>
          <RankingFilterSelectorContainer>
            <RankingFormControl
              variant="outlined"
              margin="dense"
              style={{ width: 230 }}
            >
              <ExpoSelect
                value={groupBy}
                onChange={onSelectGroupBy}
                dataSource={groupByOptions}
                fullWidth
                displayEmpty
                disabled={rankBy === 'fss_score'}
              />
            </RankingFormControl>
          </RankingFilterSelectorContainer>
        </SelectionContainer>
        <SelectionContainer>
          <TopFilterByContainer>Filter by</TopFilterByContainer>
          <DateRangeSelectorContainer>
            <DateRangeSelector
              navParams={navParams}
              isEnabled={rankBy !== 'fss_score'}
            />
          </DateRangeSelectorContainer>
        </SelectionContainer>
      </FilterOuterContainer>
      {rankTable}
    </MainContainer>
  )
}

const MainContainer = styled.div``

const SelectionContainer = styled.div`
  display: flex;
  margin-right: 25px;
`

const UpArrowImg = styled.img`
  width: 10px;
  margin: 0 0 2px 5px;
`

const DownArrowImg = styled.img`
  width: 10px;
  margin: 0 0 -1px 5px;
`

const FilterMenuItem = styled(MenuItem)`
  font-family: Lexend-Regular;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  padding-top: 10px;
  height: 40px;
`

const PageStatusDiv = styled.div`
  padding: 30px 0 0 0;
  font-family: Lexend-Regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  color: ${COLORS.Steel};
`

const TitleDiv = styled.div`
  font-family: Lexend-SemiBold;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  padding: 0;
`

const TopFilterByContainer = styled.div`
  float: left;
  font-family: Lexend-Regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  align-self: center;
`

const AvgTr = styled.tr`
  background-color: ${COLORS.Sky} !important;
`

const SelectorContainer = styled.div`
  float: left;
  margin-left: 25px;
`

const RankingFilterSelectorContainer = styled(SelectorContainer)``

const DateRangeSelectorContainer = styled(SelectorContainer)``

const RankingFormControl = styled(FormControl)`
  background-color: white;
`

const RankingSelect = styled(Select)`
  border-radius: 0;
  background-color: white;
`

const RankTableTh = styled.th<IRankTableTh>`
  font-family: Lexend-SemiBold;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  text-align: ${(props) => (props.textAlign === 'left' ? 'left' : 'center')};
`

const RankTableSortableTh = styled.th<IRankTableTh>`
  font-family: Lexend-SemiBold;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  text-align: ${(props) => (props.textAlign === 'left' ? 'left' : 'center')};
  color: ${COLORS.Plum};
  cursor: pointer;
`

const FilterOuterContainer = styled.div`
  min-width: 1280px;
  padding-bottom: 30px;
  display: flex;
  flex-wrap: wrap;
`

const RankTableTd = styled.td<IRankTableTd>`
  color: ${(props) =>
    props.danger ? COLORS.Pomodoro : props.isAvg ? 'white' : 'black'};
  font-family: ${(props) =>
    props.bold ? 'Lexend-SemiBold' : 'Lexend-Regular'};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  text-align: ${(props) => (props.textAlign === 'left' ? 'left' : 'center')};
`

const RankNumberTd = styled(RankTableTd)`
  padding-left: 12px;
`

const RankNumberAvgTd = styled(RankNumberTd)`
  backgroundcolor: ${COLORS.Plum};
`

const RankTable = styled.table`
  min-width: 50vw;
  text-align: center;
  border-collapse: collapse;
  font-family: Lexend-Regular;

  thead tr {
    border-bottom: 1px solid ${COLORS.Chalkboard};
  }

  tbody tr:nth-child(even) {
    background-color: ${COLORS.Porcelain};
  }

  tfoot tr:first-child {
    border-top: 1px solid ${COLORS.Chalkboard};
  }

  thead th {
    font-weight: 700;
    line-height: 14px;
    padding: 10px 0;
    min-width: 50px;
  }

  tbody td {
    font-style: normal;
    font-weight: 400;
    padding-top: 18px;
    padding-bottom: 18px;
  }

  tfoot td {
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    padding: 18px 0 0 0;
  }
`

const PrintContainer = styled.div`
  max-width: 650px;
`

const FlexContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  margin-bottom: 10px;
`

const VerticalSpacer = styled.div`
  height: 10px;
`

const PrintTableHeader = styled.div`
  margin: 30px 0px 10px;
  font-family: Lexend-Regular;
  font-size: 18px;
  font-weight: normal;
`

const StyledCSVLink = styled(CSVLink)`
  font-family: Lexend-Regular;
  display: flex;
  align-items: center;
  gap: 5px;
  width: 250px;
`

export default WebLargeScreenMain
