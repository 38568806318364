import { useLazyQuery } from '@apollo/client'
import _ from 'lodash'
import moment from 'moment'
import { useEffect, useState } from 'react'

import { INavParams } from 'pared/Routes/navParams'
import { track } from 'pared/analytics/delivery'
import { feature, page } from 'pared/analytics/user'
import { DELIVERY_CATEGORY, DELIVERY_DAYPART_KEY } from 'pared/constants'
import { BRAND_LOCATION_GROUP_ID } from 'pared/constants/brands'
import getDateRanges, { IDateRange } from 'pared/data/getDateRanges'
import useDefaultLocation from 'pared/hooks/useDefaultLocation'
import { getBrand } from 'pared/utils/brand'
import { getLocationCode, getLocationName } from 'pared/utils/location'
import {
  toDisplayedWaitTime,
  toPercentageString,
  toUsdString,
} from 'pared/utils/number'
import { scrollToTop } from 'pared/utils/web'

import Main from './Main'
import {
  GET_LOCATION_DATA,
  GET_LOCATION_DELIVERY_KPI,
  LIST_LOCATION_DELIVERY_DAY_TRENDS,
} from './gql'
import useDeliveryConfig from './hooks/useDeliveryConfig'

export interface ILocationData {
  id: number | null
  code: string
  name: string
  displayName: string
  doEmployeeId: number | null
  doName: string
  gmEmployeeId: number | null
  gmName: string
}

export interface ILocationDeliveryKpi {
  totalStoreCount: number
  totalOrderCount: string
  totalOrderCountRanking: string
  avgDasherWaitTime: string
  sumSubtotal: string
  sumSubtotalRanking: string
  sumErrorCharges: string
  orderWithAnyIssuePercent: string
  orderWithAnyIssuePercentRanking: string
  avgCustomerReviewScore: string
  avgCustomerReviewScoreRanking: string
  cancelledOrderPercent: string
  cancelledOrderPercentRanking: string
  delayedOrderPercent: string
  delayedOrderPercentRanking: string
  inaccurateOrderPercent: string
  inaccurateOrderPercentRanking: string
  averageTripTime: string
  averageTripTimeRanking: string
}

interface IProps {
  navParams: INavParams
}

const ONE_MINUTE_IN_SECONDS = 60
const ONE_SECOND_IN_MILLISECONDS = 1000

function Delivery({ navParams }: IProps) {
  useDefaultLocation()
  const storeId = navParams.storeId
  const dateRange = navParams.dateRange || ''

  const locationId = parseInt(storeId || '0', 10) || 0
  const brand = getBrand()
  const deliveryConfig = useDeliveryConfig(brand)
  let defaultSelectOption = DELIVERY_CATEGORY.INACCURATE_ORDER_PERCENT

  if (deliveryConfig['staffAndDayTrendsFilter']) {
    defaultSelectOption = deliveryConfig['staffAndDayTrendsFilter'].default
  }

  const [selectedDateRange, setSelectedDateRange] = useState<IDateRange | null>(
    null,
  )
  const [dayTrendsDayPart, setDayTrendsDayPart] = useState<string | null>(
    DELIVERY_DAYPART_KEY.dinner,
  )
  const [dayTrendsCategory, setDayTrendsCategory] =
    useState<string>(defaultSelectOption)

  const [
    getLocationDeliveryKpi,
    {
      loading: getLocationDeliveryKpiLoading,
      error: getLocationDeliveryKpiError,
      data: getLocationDeliveryKpiData,
    },
  ] = useLazyQuery(
    GET_LOCATION_DELIVERY_KPI(
      deliveryConfig.customizedRealTimeKpis,
      deliveryConfig.locationMetrics,
    ),
  )

  const [
    listLocationDeliveryDayTrends,
    {
      loading: listLocationDeliveryDayTrendsLoading,
      error: listLocationDeliveryDayTrendsError,
      data: listLocationDeliveryDayTrendsData,
    },
  ] = useLazyQuery(LIST_LOCATION_DELIVERY_DAY_TRENDS)

  const [getLocationData, { error: locationError, data: locationData }] =
    useLazyQuery(GET_LOCATION_DATA)

  useEffect(() => {
    async function fetchData() {
      const newAllDateRangeData = await getDateRanges()

      const years = newAllDateRangeData.years

      if (locationId) {
        getLocationData({
          variables: {
            iFilter: {
              location_ids: [locationId],
            },
          },
        })
      }

      if (Array.isArray(years) && years.length > 0) {
        let newSelectedDateRange: IDateRange | null = null
        if (dateRange) {
          newSelectedDateRange = newAllDateRangeData.dateRangeMap[dateRange]
        }

        if (!newSelectedDateRange) {
          newSelectedDateRange = newAllDateRangeData.defaultPeriod
        }

        if (locationId && newSelectedDateRange) {
          setSelectedDateRange(newSelectedDateRange)

          const periodStartDate = _.get(
            newSelectedDateRange,
            'startDateStr',
            '',
          )
          const periodEndDate = _.get(newSelectedDateRange, 'endDateStr', '')

          if (!periodStartDate || !periodEndDate) return

          getLocationDeliveryKpi({
            variables: {
              ...((deliveryConfig.customizedRealTimeKpis || []).length <= 0
                ? {}
                : {
                    iFilter: {
                      location_group_ids: [BRAND_LOCATION_GROUP_ID[brand]],
                    },
                  }),
              iLocationId: locationId,
              iStartDate: periodStartDate,
              iEndDate: periodEndDate,
            },
          })

          listLocationDeliveryDayTrends({
            variables: {
              iLocationId: locationId,
              iStartDate: periodStartDate,
              iEndDate: periodEndDate,
            },
          })
        }
      }
    }

    fetchData()
  }, [locationId, dateRange])

  useEffect(() => {
    if (navParams.pageUrl) {
      scrollToTop()
      page.visit(navParams.pageUrl)
      feature.used('Delivery')
    }
  }, [navParams.pageUrl])

  const onSelectDayTrendsDayPart = (event: any) => {
    setDayTrendsDayPart(event.target.value)
    track.dayTrendsDayPartChanged(event.target.value)
  }

  const onSelectDayTrendsCategory = (event: any) => {
    setDayTrendsCategory(event.target.value)
    track.dayTrendsCategoryChanged(event.target.value)
  }

  const rawLocation = _.get(locationData, 'listLocationDetails.nodes[0]', {})
  const doEmployee = rawLocation.directorEmployeeInfo || {}

  const gmEmployeeId = rawLocation.gmEmployeeInfo
    ? rawLocation.gmEmployeeInfo.id
    : null
  const gmName = rawLocation.gmEmployeeInfo
    ? `${rawLocation.gmEmployeeInfo.preferredName} ${rawLocation.gmEmployeeInfo.familyName}`
    : ''

  const selectedLocationId = _.get(rawLocation, 'id', 0)

  const selectedLocation = {
    id: selectedLocationId,
    closed: _.get(rawLocation, 'closed', false),
    code: getLocationCode(selectedLocationId, _.get(rawLocation, 'code', '')),
    name: getLocationName(selectedLocationId, _.get(rawLocation, 'name', '')),
    doEmployeeId: doEmployee.id || null,
    doName: doEmployee.id
      ? `${doEmployee.preferredName} ${doEmployee.familyName}`
      : '-',
    gmEmployeeId,
    gmName,
  }

  let locationDeliveryKpiSummary: ILocationDeliveryKpi = {
    totalStoreCount: 0,
    totalOrderCount: '-',
    totalOrderCountRanking: '-',
    avgDasherWaitTime: '-',
    sumSubtotal: '-',
    sumSubtotalRanking: '-',
    sumErrorCharges: '-',
    orderWithAnyIssuePercent: '-',
    orderWithAnyIssuePercentRanking: '-',
    avgCustomerReviewScore: '-',
    avgCustomerReviewScoreRanking: '-',
    cancelledOrderPercent: '-',
    cancelledOrderPercentRanking: '-',
    delayedOrderPercent: '-',
    delayedOrderPercentRanking: '-',
    inaccurateOrderPercent: '-',
    inaccurateOrderPercentRanking: '-',
    averageTripTime: '-',
    averageTripTimeRanking: '-',
  }

  if (
    getLocationDeliveryKpiData &&
    getLocationDeliveryKpiData.getLocationDeliveryKpi &&
    Array.isArray(getLocationDeliveryKpiData.getLocationDeliveryKpi.nodes)
  ) {
    const rawLocationDeliveryKpi = _.first(
      _.get(getLocationDeliveryKpiData, 'getLocationDeliveryKpi.nodes', []),
    )

    const avgCustomerReviewScore = _.get(
      rawLocationDeliveryKpi,
      'avgCustomerReviewScore',
      0,
    )
    const avgDasherWaitTime = _.get(
      rawLocationDeliveryKpi,
      'avgDasherWaitTime',
      0,
    )
    const avgDasherWaitTimeDuration = moment.duration(
      avgDasherWaitTime,
      'seconds',
    )

    let displayedAvgDashWaitTime = ''
    if (avgDasherWaitTime < ONE_MINUTE_IN_SECONDS) {
      displayedAvgDashWaitTime = 'less than a minute'
    } else {
      displayedAvgDashWaitTime =
        Math.floor(
          avgDasherWaitTimeDuration /
            ONE_MINUTE_IN_SECONDS /
            ONE_SECOND_IN_MILLISECONDS,
        ) + ' min'
    }

    const averageTripTime =
      _.get(rawLocationDeliveryKpi, 'averageTripTime', 0) / 60

    locationDeliveryKpiSummary = {
      totalStoreCount: _.get(rawLocationDeliveryKpi, 'totalStoreCount', 0),
      totalOrderCount: _.get(rawLocationDeliveryKpi, 'totalOrderCount', ''),
      totalOrderCountRanking: _.get(
        rawLocationDeliveryKpi,
        'totalOrderCountRanking',
        '',
      ),
      avgDasherWaitTime: displayedAvgDashWaitTime,
      sumSubtotal: toUsdString(
        _.get(rawLocationDeliveryKpi, 'sumSubtotal', 0) / 100,
      ),
      sumSubtotalRanking: _.get(
        rawLocationDeliveryKpi,
        'sumSubtotalRanking',
        '',
      ),
      sumErrorCharges: toUsdString(
        _.get(rawLocationDeliveryKpi, 'sumErrorCharges', 0) / 100,
      ),
      orderWithAnyIssuePercent: toPercentageString(
        _.get(rawLocationDeliveryKpi, 'orderWithAnyIssuePercent', 0) / 100,
        1,
      ),
      orderWithAnyIssuePercentRanking: _.get(
        rawLocationDeliveryKpi,
        'orderWithAnyIssuePercentRanking',
        '',
      ),
      avgCustomerReviewScore: avgCustomerReviewScore
        ? avgCustomerReviewScore.toFixed(1)
        : '-',
      avgCustomerReviewScoreRanking: _.get(
        rawLocationDeliveryKpi,
        'avgCustomerReviewScoreRanking',
        '',
      ),
      cancelledOrderPercent: toPercentageString(
        _.get(rawLocationDeliveryKpi, 'cancelledOrderPercent', 0) / 100,
        1,
      ),
      cancelledOrderPercentRanking: _.get(
        rawLocationDeliveryKpi,
        'cancelledOrderPercentRanking',
        '',
      ),
      delayedOrderPercent: toPercentageString(
        _.get(rawLocationDeliveryKpi, 'delayedOrderPercent', 0) / 100,
        1,
      ),
      delayedOrderPercentRanking: _.get(
        rawLocationDeliveryKpi,
        'delayedOrderPercentRanking',
        '',
      ),
      inaccurateOrderPercent: toPercentageString(
        _.get(rawLocationDeliveryKpi, 'inaccurateOrderPercent', 0) / 100,
        1,
      ),
      inaccurateOrderPercentRanking: _.get(
        rawLocationDeliveryKpi,
        'inaccurateOrderPercentRanking',
        '',
      ),
      averageTripTime: averageTripTime
        ? `${averageTripTime.toFixed(1)} min`
        : '-',
      averageTripTimeRanking: _.get(
        rawLocationDeliveryKpi,
        'averageTripTimeRanking',
        '',
      ),
    }

    if (getLocationDeliveryKpiData.customizedRankLocationDelivery) {
      const customizedData =
        getLocationDeliveryKpiData.customizedRankLocationDelivery.nodes.find(
          (n) => n.locationId === locationId,
        ) || {}

      Object.keys(customizedData).forEach((key) => {
        switch (key) {
          case 'totalOrderCount':
            locationDeliveryKpiSummary[key] =
              customizedData[key]?.toLocaleString('en-US')
            break

          case 'sumSubtotal':
          case 'completedDisputeAmount':
            locationDeliveryKpiSummary[key] = toUsdString(
              customizedData[key] / 100,
            )
            break

          default:
            locationDeliveryKpiSummary[key] = customizedData[key]
            break
        }
      })
    }

    if (getLocationDeliveryKpiData.listLocationMetricValues) {
      const listLocationMetricValues =
        getLocationDeliveryKpiData?.listLocationMetricValues.nodes.find(
          (n) => n.metricSummaryData,
        )?.metricSummaryData || {}

      Object.keys(listLocationMetricValues).forEach((key) => {
        const fieldKey = _.camelCase(key)

        switch (listLocationMetricValues[key].unit) {
          case 'SECONDS':
            locationDeliveryKpiSummary[fieldKey] = toDisplayedWaitTime(
              listLocationMetricValues[key].value,
            )
            break

          default:
            locationDeliveryKpiSummary[fieldKey] =
              listLocationMetricValues[key].value
            break
        }
      })
    }
  }

  let dayTrendsInfo: any[] = []

  if (
    listLocationDeliveryDayTrendsData &&
    listLocationDeliveryDayTrendsData.listLocationDeliveryDayTrends &&
    Array.isArray(
      listLocationDeliveryDayTrendsData.listLocationDeliveryDayTrends.nodes,
    )
  ) {
    const rawDayTrends = _.get(
      listLocationDeliveryDayTrendsData,
      'listLocationDeliveryDayTrends.nodes',
      [],
    )
    const filteredDayTrendsByDayPart = _.filter(rawDayTrends, (t) => {
      const dayPart = _.get(t, 'dayPart', '')
      return dayPart === dayTrendsDayPart
    })

    _.each(filteredDayTrendsByDayPart, (d) => {
      const dayOfWeek: number = _.get(d, 'dayOfWeek', 0)
      let value
      if (dayTrendsCategory === DELIVERY_CATEGORY.AVG_CUSTOMER_REVIEW_SCORE) {
        const avgCustoerReviewScore = _.get(d, [dayTrendsCategory], 0)
        value = avgCustoerReviewScore ? avgCustoerReviewScore.toFixed(1) : '-'
      } else if (dayTrendsCategory === DELIVERY_CATEGORY.AVERAGE_TRIP_TIME) {
        value = _.get(d, [dayTrendsCategory], 0) / 60
        value = value ? value.toFixed(1) : '-'
      } else {
        value = toPercentageString(_.get(d, [dayTrendsCategory], 0) / 100, 1)
      }

      dayTrendsInfo[dayOfWeek] = {
        value,
        totalOrderCount: _.get(d, 'totalOrderCount', 0) || 0,
      }
    })
  }

  if (getLocationDeliveryKpiLoading) {
    locationDeliveryKpiSummary = {
      totalStoreCount: 'loading',
      totalOrderCount: 'loading',
      totalOrderCountRanking: 'loading',
      avgDasherWaitTime: 'loading',
      sumSubtotal: 'loading',
      sumSubtotalRanking: 'loading',
      sumErrorCharges: 'loading',
      orderWithAnyIssuePercent: 'loading',
      orderWithAnyIssuePercentRanking: 'loading',
      avgCustomerReviewScore: 'loading',
      avgCustomerReviewScoreRanking: 'loading',
      cancelledOrderPercent: 'loading',
      cancelledOrderPercentRanking: 'loading',
      delayedOrderPercent: 'loading',
      delayedOrderPercentRanking: 'loading',
      inaccurateOrderPercent: 'loading',
      inaccurateOrderPercentRanking: 'loading',
      averageTripTime: 'loading',
      averageTripTimeRanking: 'loading',
    }
  }

  return (
    <Main
      navParams={navParams}
      locationDeliveryKpiSummary={locationDeliveryKpiSummary}
      selectedDateRange={selectedDateRange}
      locationId={locationId}
      onSelectDayTrendsDayPart={onSelectDayTrendsDayPart}
      dayTrendsDayPart={dayTrendsDayPart}
      dayTrendsInfo={dayTrendsInfo}
      onSelectDayTrendsCategory={onSelectDayTrendsCategory}
      dayTrendsCategory={dayTrendsCategory}
      selectedLocation={selectedLocation}
    />
  )
}

export default Delivery
