import useItem, { itemConfigs } from '../../../tableV2/hooks/common/useItem'
import useItemTrend, {
  itemTrendConfigs,
} from '../../../tableV2/hooks/common/useItemTrend'
import useLocationDayPartPie, {
  locationDayPartPieConfigs,
} from './useLocationDayPartPie'
import useTrendLocationGroupDiscount, {
  trendLocationGroupDiscountConfigs,
} from './useTrendLocationGroupDiscounts'
import useTrendLocationGroupFinancialKpis, {
  trendLocationGroupFinancialKpisConfigs,
} from './useTrendLocationGroupFinancialKpis'
import useTrendLocationGroupLossPreventions, {
  trendLocationGroupLossPreventionConfigs,
} from './useTrendLocationGroupLossPreventions'
import useTrendLocationRevenueCenterSales, {
  trendLocationRevenueCenterSalesConfigs,
} from './useTrendLocationRevenueCenterSales'

export const configs = {
  trendLocationGroupFinancialKpis: trendLocationGroupFinancialKpisConfigs,
  trendLocationGroupDiscount: trendLocationGroupDiscountConfigs,
  trendLocationGroupLossPrevention: trendLocationGroupLossPreventionConfigs,
  locationDayPartPie: locationDayPartPieConfigs,
  trendLocationRevenueCenterSales: trendLocationRevenueCenterSalesConfigs,
  item: itemConfigs,
  itemTrend: itemTrendConfigs,
}

export const api = {
  trendLocationGroupFinancialKpis: useTrendLocationGroupFinancialKpis,
  trendLocationGroupDiscount: useTrendLocationGroupDiscount,
  trendLocationGroupLossPrevention: useTrendLocationGroupLossPreventions,
  locationDayPartPie: useLocationDayPartPie,
  trendLocationRevenueCenterSales: useTrendLocationRevenueCenterSales,
  item: useItem,
  itemTrend: useItemTrend,
}
