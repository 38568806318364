import useBrands from 'pared/layouts/hooks/useBrands'
import { getBrand } from 'pared/utils/brand'

// Team Gql
export const RANK_LOCATION_TURNOVERS = `
  query RankLocationTurnovers($iStartDate: Date!, $iEndDate: Date!, $iFilter: JSON!) {
    rankLocationTurnovers(iStartDate: $iStartDate, iEndDate: $iEndDate, iFilter: $iFilter) {
      nodes {
        locationId
        locationInfo {
          id
          code
          name
        }
        groupByStartDate
        groupByEndDate

        hourlyTurnoverCount
        salariedTurnoverCount
        hourlyTurnoverCountInLast12Months
        salariedTurnoverCountInLast12Months

        avgHourlyTurnoverPercent
        avgSalariedTurnoverPercent
        annualizedHourlyTurnoverPercent
        annualizedSalariedTurnoverPercent
      }
    }
  }
`

export const RANK_LOCATION_TURNOVERS_V2 = `
  query RankLocationTurnoversV2($iStartDate: Date!, $iEndDate: Date!, $iFilter: JSON!) {
    rankLocationTurnoversV2(iStartDate: $iStartDate, iEndDate: $iEndDate, iFilter: $iFilter) {
      nodes {
        locationId
        locationInfo {
          id
          code
          name
        }
        groupByStartDate
        groupByEndDate

        hourlyTurnoverCount
        salariedTurnoverCount
        hourlyTurnoverCountInLast12Months
        salariedTurnoverCountInLast12Months

        avgHourlyTurnoverPercent
        avgSalariedTurnoverPercent
        annualizedHourlyTurnoverPercent
        annualizedSalariedTurnoverPercent
      }
    }
  }
`

export const RANK_LOCATION_GROUP_TURNOVERS = `
  query RankLocationGroupTurnovers($iStartDate: Date!, $iEndDate: Date!, $iFilter: JSON!) {
    rankLocationGroupTurnovers(iStartDate: $iStartDate, iEndDate: $iEndDate, iFilter: $iFilter) {
      nodes {
        locationGroupId
        locationGroupName
        doEmployeeId
        doEmployeeInfo {
          id
          preferredName
          familyName
        }
        groupByStartDate
        groupByEndDate

        hourlyTurnoverCount
        salariedTurnoverCount
        hourlyTurnoverCountInLast12Months
        salariedTurnoverCountInLast12Months

        avgHourlyTurnoverPercent
        avgSalariedTurnoverPercent
        annualizedHourlyTurnoverPercent
        annualizedSalariedTurnoverPercent
      }
    }
  }
`

export const RANK_LOCATION_GROUP_TURNOVERS_V2 = `
  query RankLocationGroupTurnoversV2($iStartDate: Date!, $iEndDate: Date!, $iFilter: JSON!) {
    rankLocationGroupTurnoversV2(iStartDate: $iStartDate, iEndDate: $iEndDate, iFilter: $iFilter) {
      nodes {
        locationGroupId
        locationGroupName
        doEmployeeId
        doEmployeeInfo {
          id
          preferredName
          familyName
        }
        groupByStartDate
        groupByEndDate

        hourlyTurnoverCount
        salariedTurnoverCount
        hourlyTurnoverCountInLast12Months
        salariedTurnoverCountInLast12Months

        avgHourlyTurnoverPercent
        avgSalariedTurnoverPercent
        annualizedHourlyTurnoverPercent
        annualizedSalariedTurnoverPercent
      }
    }
  }
`

// Salesmanship Gql

export const RANK_LOCATION_SALESMANSHIP_KPIS = `
  query RankLocationSalesmanshipKpis($iStartDate: Date!, $iEndDate: Date!, $iFilter: JSON!) {
    rankLocationSalesmanshipKpis(iStartDate: $iStartDate, iEndDate: $iEndDate, iFilter: $iFilter) {
      nodes {
        locationId
        appetizerPpa
        dessertPpa
        grossSalesPerLaborHour
        lbwPpa
        ppa
        locationInfo {
          id
          code
          name
        }
        avgAppetizerPpa
        avgGrossSalesPerLaborHour
        avgDessertPpa
        avgLbwPpa
        avgPpa
      }
    }
  }
`

export const RANK_LOCATION_GROUP_SALESMANSHIP_KPIS = `
  query RankLocationGroupSalesmanshipKpis($iStartDate: Date!, $iEndDate: Date!, $iFilter: JSON!) {
    rankLocationGroupSalesmanshipKpis(iStartDate: $iStartDate, iEndDate: $iEndDate, iFilter: $iFilter) {
      nodes {
        doEmployeeId
        appetizerPpa
        dessertPpa
        grossSalesPerLaborHour
        lbwPpa
        ppa
        doEmployeeInfo {
          id
          preferredName
          familyName
        }
        avgAppetizerPpa
        avgGrossSalesPerLaborHour
        avgDessertPpa
        avgLbwPpa
        avgPpa
      }
    }
  }
`

// Guest Gql

export const RANK_LOCATION_GUEST_KPIS = (rankingType: string) => `
  query RankLocationGuestKpis($iStartDate: Date!, $iEndDate: Date!, $iFilter: JSON!) {
    rankLocationGuestKpis: rankLocationGuestKpisV2(
      iStartDate: $iStartDate
      iRankingType: "${rankingType}"
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) {
      nodes {
        locationInfo {
          id
        }
        ratingCount
        ratingScore
      }
    }
  }
`

export const LIST_LOCATION_GUEST_DELIVERY_RANKINGS = `
  query ListLocationGuestRankings($iStartDate: Date!, $iEndDate: Date!, $iFilter: JSON!) {
    rankLocationGuestKpis: rankLocationGuestKpisV2(
      iStartDate: $iStartDate
      iRankingType: "DELIVERY"
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) {
      nodes {
        locationInfo {
          id
        }
        ratingCount
        ratingScore
      }
    }
  }
`

export const RANK_LOCATION_GROUP_GUEST_KPIS = (rankingType: string) => `
  query RankLocationGroupGuestKpis($iStartDate: Date!, $iEndDate: Date!, $iFilter: JSON!) {
    rankLocationGroupGuestKpis: rankLocationGroupGuestKpisV2(
      iStartDate: $iStartDate
      iRankingType: "${rankingType}"
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) {
      nodes {
        ratingCount
        ratingScore
        employeeInfo {
          id
          firstName: preferredName
          lastName: familyName
        }
      }
    }
  }
`

export const LIST_DO_GUEST_DELIVERY_RANKINGS = `
  query ListDoGuestRankings($iStartDate: Date!, $iEndDate: Date!, $iFilter: JSON!) {
    rankLocationGroupGuestKpis: rankLocationGroupGuestKpisV2(
      iStartDate: $iStartDate
      iRankingType: "DELIVERY"
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) {
      nodes {
        ratingCount
        ratingScore
        employeeInfo {
          id
          firstName: preferredName
          lastName: familyName
        }
      }
    }
  }
`

// Delivery Gql

export const LIST_LOCATION_DELIVERY_RANKINGS = `
  query ListLocationDeliveryRankings($iStartDate: Date!, $iEndDate: Date!, $iFilter: JSON!) {
    rankLocationDeliveryKpis(iStartDate: $iStartDate, iEndDate: $iEndDate, iFilter: $iFilter) {
      nodes {
        locationId
        orderWithAnyIssuePercent
        sumSubtotal
        avgCustomerReviewScore
        totalOrderCount
        averageTripTime
      }
    }
  }
`

export const CUSTOMIZED_RANK_LOCATION_DELIVERY = `
  query CustomizedRankLocationDelivery($iStartDate: Date!, $iEndDate: Date!, $iFilter: JSON!) {
    rankLocationDeliveryKpis: customizedRankLocationDelivery(iStartDate: $iStartDate, iEndDate: $iEndDate, iFilter: $iFilter) {
      nodes {
        locationId
        sumSubtotal
      }
    }
  }
`

export const LIST_DO_DELIVERY_RANKINGS = `
  query ListDoDeliveryRankings($iStartDate: Date!, $iEndDate: Date!, $iFilter: JSON!) {
    rankLocationGroupDeliveryKpis(iStartDate: $iStartDate, iEndDate: $iEndDate, iFilter: $iFilter) {
      nodes {
        locationGroupId
        doEmployeeId
        orderWithAnyIssuePercent
        sumSubtotal
        avgCustomerReviewScore
        doEmployeeInfo {
          id
          firstName: preferredName
          lastName: familyName
        }
        totalOrderCount
        averageTripTime
      }
    }
  }
`

export const CUSTOMIZED_RANK_LOCATION_GROUP_DELIVERY = `
  query CustomizedRankLocationGroupDelivery($iStartDate: Date!, $iEndDate: Date!, $iFilter: JSON!) {
    rankLocationGroupDeliveryKpis: customizedRankLocationGroupDelivery(iStartDate: $iStartDate, iEndDate: $iEndDate, iFilter: $iFilter) {
      nodes {
        locationGroupId
        sumSubtotal
      }
    }
  }
`

// Endpoints utilizing normalized backend

export const RANK_LOCATION_SCORECARD = `
  query RankLocationScorecard($iStartDate: Date!, $iEndDate: Date!, $iFilter: JSON!) {
    rankLocationScorecard(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) {
      nodes {
        locationId
        locationName
        locationCode
        totalScore
        rank
      }
    }
  }
`

export const RANK_LOCATION_GROUP_SCORECARD = `
  query RankLocationGroupScorecard($iStartDate: Date!, $iEndDate: Date!, $iFilter: JSON!) {
    rankLocationGroupScorecard(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) {
      nodes {
        locationGroupId
        locationGroupName
        doEmployeeId
        totalScore
        rank
      }
    }
  }
`

export const LIST_LOCATION_KPI_RANKINGS = `
  query ListLocationKpiRankings($iStartDate: Date!, $iEndDate: Date!, $iFilter: JSON!) {
    listLocationKpiRankings(
        iStartDate: $iStartDate
        iEndDate: $iEndDate
        iFilter: $iFilter
    ) {
      nodes {
        locationId
        locationCode
        locationName
        kpi
        rankingData
      }
    }
  }
`

export const LIST_LOCATION_GROUP_KPI_RANKINGS = `query ListLocationGroupKpiRankings($iStartDate: Date!, $iEndDate: Date!, $iFilter: JSON!) {
    listLocationGroupKpiRankings(
        iStartDate: $iStartDate
        iEndDate: $iEndDate
        iFilter: $iFilter
      ) {
        nodes {
          locationGroupId
          doEmployeeId
          locationGroupName
          kpi
          rankingData
      }
    }
  }
`

export const GET_CUSTOMIZED_REPORT = `
  query GetCustomizedReport($iReportName: String!, $iInputParams: JSON!) {
    getCustomizedReport(
      iCustomizedReportName: $iReportName,
      iInputParams: $iInputParams
    ) {
      nodes {
        reportName
        reportDefinition
        reportResult
      }
    }
  }
`

export const LIST_LOCATION_SALESMANSHIP_KPIS = `
  query ListLocationSalesmanshipKpis(
    $iEndDate: Date!
    $iStartDate: Date!
    $iFilter: JSON!
  ) {
    listLocationSalesmanshipKpis: listLocationSalesmanshipKpisV2E1(
      iEndDate: $iEndDate
      iStartDate: $iStartDate
      iFilter: $iFilter
    ) {
      nodes {
        locationInfo {
          id
          code
          name
        }
        categorizedSalesDetails
        totalSalesDetails
      }
    }
  }
`

export const LIST_LOCATION_GROUP_SALESMANSHIP_KPIS = `
  query ListLocationGroupSalesmanshipKpis(
    $iEndDate: Date!
    $iStartDate: Date!
    $iFilter: JSON!
  ) {
    listLocationGroupSalesmanshipKpis: listLocationGroupSalesmanshipKpisV2E1(
      iEndDate: $iEndDate
      iStartDate: $iStartDate
      iFilter: $iFilter
    ) {
      nodes {
        locationGroupId
        locationGroupName
        categorizedSalesDetails
        totalSalesDetails
      }
    }
  }
`

export const LIST_LOCATION_SPEED_OF_SERVICE = `
  query ListLocationSpeedOfService(
    $iStartDate: Date!
    $iEndDate: Date!
    $iFilter: JSON!
  ) {
    listLocationSpeedOfService(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) {
      nodes {
        locationInfo {
          id
          code
          name
        }
        sosDetails
      }
    }
  }
`

export const LIST_LOCATION_GROUP_SPEED_OF_SERVICE = `
  query ListLocationGroupSpeedOfService(
    $iStartDate: Date!
    $iEndDate: Date!
    $iFilter: JSON!
  ) {
    listLocationGroupSpeedOfService(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) {
      nodes {
        locationGroupId
        locationGroupName
        sosDetails
      }
    }
  }
`

export const LIST_ENTREES_PER_LABOR_HOUR = `
  query ListEntreesPerLaborHour(
    $iStartDate: Date!
    $iEndDate: Date!
    $iFilter: JSON!
    $isBreakdownByLocationGroup: Boolean!
  ) {
    listLocationEntreesPerLaborHour(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) @skip(if: $isBreakdownByLocationGroup) {
      nodes {
        locationId
        details
      }
    }

    listLocationGroupEntreesPerLaborHour(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) @include(if: $isBreakdownByLocationGroup) {
      nodes {
        locationGroupId
        locationGroupName
        details
      }
    }
  }
`

export const LIST_UPSIZE_PERCENT = `
  query ListUpsizePercent(
    $iStartDate: Date!
    $iEndDate: Date!
    $iFilter: JSON!
    $isBreakdownByLocationGroup: Boolean!
  ) {
    listLocationUpsizePercent(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) @skip(if: $isBreakdownByLocationGroup) {
      nodes {
        locationId
        upsizePercent
      }
    }

    listLocationGroupUpsizePercent(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) @include(if: $isBreakdownByLocationGroup) {
      nodes {
        locationGroupId
        locationGroupName
        upsizePercent
      }
    }
  }
`

export const LIST_ZENPUT_SCORE = `
  query ListZenputScore(
    $iStartDate: Date!
    $iEndDate: Date!
    $iFilter: JSON!
    $isBreakdownByLocationGroup: Boolean!
  ) {
    listLocationZenputScore(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) @skip(if: $isBreakdownByLocationGroup) {
      nodes {
        locationId
        zenputScore: value
      }
    }

    listLocationGroupZenputScore(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) @include(if: $isBreakdownByLocationGroup) {
      nodes {
        locationGroupId
        locationGroupName
        zenputScore: value
      }
    }
  }
`

export const LIST_CAVAYOU_SCORE = `
  query ListCavayouScore(
    $iStartDate: Date!
    $iEndDate: Date!
    $iFilter: JSON!
    $isBreakdownByLocationGroup: Boolean!
  ) {
    listLocationCavayouScore(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) @skip(if: $isBreakdownByLocationGroup) {
      nodes {
        locationId
        cavayouScore: value
      }
    }

    listLocationGroupCavayouScore(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) @include(if: $isBreakdownByLocationGroup) {
      nodes {
        locationGroupId
        locationGroupName
        cavayouScore: value
      }
    }
  }
`

export const LIST_LOCATION_ACR_SCORE_RANKINGS = `
  query ListLocationAcrScoreRankings($iStartDate: Date!, $iEndDate: Date!, $iFilter: JSON!) {
    listLocationAcrScoreRankings(
        iStartDate: $iStartDate
        iEndDate: $iEndDate
        iFilter: $iFilter
    ) {
      nodes {
        locationId
        locationCode
        locationName
        kpi
        rankingData
      }
    }
  }
`

export const LIST_LOCATION_GROUP_ACR_SCORE_RANKINGS = `query ListLocationGroupAcrScoreRankings($iStartDate: Date!, $iEndDate: Date!, $iFilter: JSON!) {
  listLocationGroupAcrScoreRankings(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) {
      nodes {
        locationGroupId
        doEmployeeId
        locationGroupName
        kpi
        rankingData
    }
  }
}
`

export const LIST_LOCATION_INBOUND_CALLS = `
  query ListLocationInboundCalls($iStartDate: Date!, $iEndDate: Date!, $iFilter: JSON!) {
    listLocationInboundCalls(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) {
      nodes {
        locationId
        inboundTotal
        inboundAnswered
        percentAnswered
        inboundMissed
        percentMissed
        inboundAbandoned
        percentAbandoned
        missedOpportunity
      }
    }
  }
`

export const LIST_LOCATION_GROUP_INBOUND_CALLS = `
  query ListLocationGroupInboundCalls($iStartDate: Date!, $iEndDate: Date!, $iFilter: JSON!) {
    listLocationGroupInboundCalls(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) {
      nodes {
        locationGroupId
        locationGroupName
        inboundTotal
        inboundAnswered
        percentAnswered
        inboundMissed
        percentMissed
        inboundAbandoned
        percentAbandoned
        missedOpportunity
      }
    }
  }
`

export const LIST_LOCATION_LOSS_PREVENTION_KPIS = `
  query ListLocationLossPreventionKpis(
    $iStartDate: Date!
    $iEndDate: Date!
    $iFilter: JSON!
  ) {
    listLocationLossPreventionKpis(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) {
      nodes {
        locationInfo {
          id
          code
          name
        }
        cashOverOrShort
        totalComps
        compsPercent
        inaccurateCashierShiftCount
        inaccurateCashierShiftPercent
        lossPreventionItems
        overRingAmount
        overRingPercent
      }
    }
  }
`

export const LIST_LOCATION_GROUP_LOSS_PREVENTION_KPIS = `
  query ListLocationGroupLossPreventionKpis(
    $iStartDate: Date!
    $iEndDate: Date!
    $iFilter: JSON!
  ) {
    listLocationGroupLossPreventionKpis(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) {
      nodes {
        locationGroupId
        locationGroupName
        cashOverOrShort
        oloOverOrShort
        inaccurateCashierShiftPercent
        compsTotalCount
        compsTotalAmount
        compsPercent
        lossPreventionItems
        overRingAmount
        overRingPercent
      }
    }
  }
`

export const LIST_LOCATION_SMG_KPIS = `
  query ListLocationSmgKpis(
    $iStartDate: Date!
    $iEndDate: Date!
    $iFilter: JSON!
  ) {
    listLocationSmgKpis(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) {
      nodes {
        locationInfo {
          id
          code
          name
        }
        totalSurveys
        dissatisfactionPercent
        inaccuratePercent
      }
    }
  }
`

export const LIST_LOCATION_GROUP_SMG_KPIS = `
  query ListLocationGroupSmgKpis(
    $iStartDate: Date!
    $iEndDate: Date!
    $iFilter: JSON!
  ) {
    listLocationGroupSmgKpis(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) {
      nodes {
        locationGroupId
        locationGroupName
        totalSurveys
        dissatisfactionPercent
        inaccuratePercent
      }
    }
  }
`

export const LIST_LOCATION_GUEST_OPENTABLE_KPIS = `
  query ListLocationGuestOpentableKpis(
    $iFilter: JSON!
    $iEndDate: Date!
    $iStartDate: Date!
  ) {
    listLocationGuestOpentableKpis(
      iFilter: $iFilter
      iEndDate: $iEndDate
      iStartDate: $iStartDate
    ) {
      nodes {
        locationId
        locationInfo {
          id
          code
          name
        }
        ratingScore
        opentableRatingCount: ratingCount
        ratingScoreChange
        seatedCovers
        seatedCoversChange
        phoneCovers
        phoneCoversChange
        walkinCovers
        walkinCoversChange
        yourNetworkCovers
        yourNetworkCoversChange
        opentableCovers
        opentableCoversChange
        noShowRate
        noShowRateChange
        ratingRank
      }
    }
  }
`

export const LIST_LOCATION_GROUP_GUEST_OPENTABLE_KPIS = `
  query listLocationGroupGuestOpentableKpis(
    $iFilter: JSON!
    $iStartDate: Date!
    $iEndDate: Date!
  ) {
    listLocationGroupGuestOpentableKpis(
      iFilter: $iFilter
      iStartDate: $iStartDate
      iEndDate: $iEndDate
    ) {
      nodes {
        employeeInfo {
          id
          firstName: preferredName
          lastName: familyName
        }
        ratingScore
        opentableRatingCount: ratingCount
        ratingScoreChange
        seatedCovers
        seatedCoversChange
        phoneCovers
        phoneCoversChange
        walkinCovers
        walkinCoversChange
        yourNetworkCovers
        yourNetworkCoversChange
        opentableCovers
        opentableCoversChange
        noShowRate
        noShowRateChange
      }
    }
  }
`

export const RANK_LOCATION_DISCOUNT_CAMPAIGN = `
  query RankLocationDiscountCampaign(
    $iFilter: JSON!
    $iStartDate: Date!
    $iEndDate: Date!
  ) {
    rankLocationDiscountCampaign(
      iFilter: $iFilter
      iStartDate: $iStartDate
      iEndDate: $iEndDate
    ) {
      nodes {
        locationInfo {
          id
        }
        totalComps
        totalCounts
        discountCheckAvg: checkAvg
        discountPpa: ppa
      }
    }
  }
`

export const RANK_LOCATION_GROUP_DISCOUNT_CAMPAIGN = `
  query RankLocationGroupDiscountCampaign(
    $iFilter: JSON!
    $iStartDate: Date!
    $iEndDate: Date!
  ) {
    rankLocationGroupDiscountCampaign(
      iFilter: $iFilter
      iStartDate: $iStartDate
      iEndDate: $iEndDate
    ) {
      nodes {
        employeeInfo {
          id
          firstName: preferredName
          lastName: familyName
        }
        totalComps
        totalCounts
        discountCheckAvg: checkAvg
        discountPpa: ppa
      }
    }
  }
`

export const LIST_HME = `
  query ListHme(
    $iStartDate: Date!
    $iEndDate: Date!
    $iFilter: JSON!
    $isBreakdownByLocationGroup: Boolean!
  ) {
    listLocationHme(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) @skip(if: $isBreakdownByLocationGroup) {
      nodes {
        locationId
        locationInfo {
          id
          code
          name
        }
        speedOfService: avg
        goalPercent
      }
    }

    listLocationGroupHme(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) @include(if: $isBreakdownByLocationGroup) {
      nodes {
        locationGroupId
        locationGroupName
        speedOfService: avg
        goalPercent
      }
    }
  }
`

export const METRIC_VALUES = `
  query metricValues(
    $iStartDate: Date!
    $iEndDate: Date!
    $iFilter: JSON!
    $isBreakdownByLocationGroup: Boolean!
  ) {
    listLocationMetricValues(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) @skip(if: $isBreakdownByLocationGroup) {
      nodes {
        locationId
        metricData
      }
    }

    listLocationGroupMetricValues(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) @include(if: $isBreakdownByLocationGroup) {
      nodes {
        locationGroupId
        metricData
      }
    }
  }
`

export const getRankingsGql = (filterBy: string, groupBy: string) => {
  const brand = getBrand()

  if (groupBy !== 'store') {
    switch (filterBy) {
      case 'scorecard':
        return RANK_LOCATION_GROUP_SCORECARD
      case 'net_sales':
      case 'gross_sales':
      case 'cogs':
      case 'cogs_budget_variance':
      case 'avt':
      case 'avt_percent_monthly':
      case 'rcp':
      case 'rcp_budget_variance':
      case 'net_sales_sss':
      case 'gross_sales_sss':
      case 'net_sales_budget_variance':
      case 'gross_sales_budget_variance':
      case 'hourly_labor_percent':
      case 'hourly_labor_budget_variance':
      case 'plv_percent':
      case 'ops_comps':
      case 'daypart':
      case 'sales_per_labor_hour':
      case 'total_check_average':
      case 'total_guest_average':
      case 'total_check_count':
        return LIST_LOCATION_GROUP_KPI_RANKINGS
      case 'annualizedHourlyTurnoverV2':
        return RANK_LOCATION_GROUP_TURNOVERS_V2
      case 'annualizedHourlyTurnover':
      case 'annualizedManagerTurnover':
      case 'hourlyTurnoverRateAndCount':
      case 'managerTurnoverRateAndCount':
        return RANK_LOCATION_GROUP_TURNOVERS
      case 'salesPerLaborHour':
      case 'ppa':
      case 'appetizerPpa':
      case 'lbwPpa':
      case 'dessertPpa':
        return RANK_LOCATION_GROUP_SALESMANSHIP_KPIS
      case 'momentfeed':
        return RANK_LOCATION_GROUP_GUEST_KPIS('momentfeed')
      case 'google':
        return RANK_LOCATION_GROUP_GUEST_KPIS('google')
      case 'yelp':
        return RANK_LOCATION_GROUP_GUEST_KPIS('yelp')
      case 'delivery':
        return LIST_DO_GUEST_DELIVERY_RANKINGS
      case 'opentable':
        return LIST_LOCATION_GROUP_GUEST_OPENTABLE_KPIS
      case 'deliverySales':
        if (['bibibop'].includes(brand))
          return CUSTOMIZED_RANK_LOCATION_GROUP_DELIVERY
        return LIST_DO_DELIVERY_RANKINGS
      case 'deliveryOrders':
      case 'averageTripTime':
      case 'orderWithAnyIssues':
      case 'combinedStarRating':
        return LIST_DO_DELIVERY_RANKINGS
      case 'classicUsageVariancePercent':
      case 'bonelessUsageVariancePercent':
      case 'tendersUsageVariancePercent':
      case 'mwbAvt':
        return GET_CUSTOMIZED_REPORT
      case 'totalPpa':
      case 'salesPerHour':
      case 'checkAvg':
      case 'totalSidesSales':
      case 'friesSales':
      case 'cornSales':
      case 'brownieSales':
      case 'entreePremiumIncidents':
      case 'curatedIncidents':
      case 'sideAttachmentRate':
      case 'dessertAttachmentRate':
      case 'beverageAttachmentRate':
      case 'baconAttachmentRate':
      case 'cheeseAttachmentRate':
      case 'treatAttachmentRate':
      case 'cornAttachmentRate':
      case 'waterAttachmentRate':
      case 'saucesAttachmentRate':
      case 'sticksAttachmentRate':
      case 'dessertsAttachmentRate':
      case 'beveragesAttachmentRate':
      case 'jalapenoAttachmentRate':
      case 'dessertRate':
      case 'comboRate':
      case 'drinkRate':
      case 'appetizerPpaV2':
      case 'lbwPpaV2':
      case 'dessertPpaV2':
      case 'naBeveragePpa':
      case 'sidesPpa':
        return LIST_LOCATION_GROUP_SALESMANSHIP_KPIS
      case 'sosPickup':
        return LIST_LOCATION_GROUP_SPEED_OF_SERVICE
      case 'entreesPerLaborHour':
        return LIST_ENTREES_PER_LABOR_HOUR
      case 'upsizePercent':
        return LIST_UPSIZE_PERCENT
      case 'zenput':
        return LIST_ZENPUT_SCORE
      case 'cavayou':
        return LIST_CAVAYOU_SCORE
      case 'acr_score':
        return LIST_LOCATION_GROUP_ACR_SCORE_RANKINGS
      case 'inboundCalls':
        return LIST_LOCATION_GROUP_INBOUND_CALLS
      case 'voids':
      case 'discounts':
      case 'cashOverOrShort':
        return LIST_LOCATION_GROUP_LOSS_PREVENTION_KPIS
      case 'smg':
        return LIST_LOCATION_GROUP_SMG_KPIS
      case 'marketingAnalysis':
        return RANK_LOCATION_GROUP_DISCOUNT_CAMPAIGN
      case 'hme':
        return LIST_HME
      case 'avgDeliveryTimeDoordash':
        return METRIC_VALUES
      default:
        return RANK_LOCATION_GROUP_SCORECARD
    }
  } else {
    switch (filterBy) {
      case 'scorecard':
        return RANK_LOCATION_SCORECARD
      case 'net_sales':
      case 'gross_sales':
      case 'cogs':
      case 'cogs_budget_variance':
      case 'avt':
      case 'avt_percent_monthly':
      case 'rcp':
      case 'rcp_budget_variance':
      case 'net_sales_sss':
      case 'gross_sales_sss':
      case 'net_sales_budget_variance':
      case 'gross_sales_budget_variance':
      case 'hourly_labor_percent':
      case 'hourly_labor_budget_variance':
      case 'plv_percent':
      case 'ops_comps':
      case 'daypart':
      case 'sales_per_labor_hour':
      case 'total_check_average':
      case 'total_guest_average':
      case 'total_check_count':
        return LIST_LOCATION_KPI_RANKINGS
      case 'annualizedHourlyTurnoverV2':
        return RANK_LOCATION_TURNOVERS_V2
      case 'annualizedHourlyTurnover':
      case 'annualizedManagerTurnover':
      case 'hourlyTurnoverRateAndCount':
      case 'managerTurnoverRateAndCount':
        return RANK_LOCATION_TURNOVERS
      case 'salesPerLaborHour':
      case 'ppa':
      case 'appetizerPpa':
      case 'lbwPpa':
      case 'dessertPpa':
        return RANK_LOCATION_SALESMANSHIP_KPIS
      case 'momentfeed':
      case 'google':
      case 'yelp':
        return RANK_LOCATION_GUEST_KPIS(filterBy)
      case 'delivery':
        return LIST_LOCATION_GUEST_DELIVERY_RANKINGS
      case 'opentable':
        return LIST_LOCATION_GUEST_OPENTABLE_KPIS
      case 'deliverySales':
        if (['bibibop'].includes(brand))
          return CUSTOMIZED_RANK_LOCATION_DELIVERY
        return LIST_LOCATION_DELIVERY_RANKINGS
      case 'deliveryOrders':
      case 'averageTripTime':
      case 'orderWithAnyIssues':
      case 'combinedStarRating':
        return LIST_LOCATION_DELIVERY_RANKINGS
      case 'classicUsageVariancePercent':
      case 'bonelessUsageVariancePercent':
      case 'tendersUsageVariancePercent':
      case 'mwbAvt':
        return GET_CUSTOMIZED_REPORT
      case 'totalPpa':
      case 'salesPerHour':
      case 'checkAvg':
      case 'totalSidesSales':
      case 'friesSales':
      case 'cornSales':
      case 'brownieSales':
      case 'entreePremiumIncidents':
      case 'curatedIncidents':
      case 'sideAttachmentRate':
      case 'dessertAttachmentRate':
      case 'beverageAttachmentRate':
      case 'baconAttachmentRate':
      case 'cheeseAttachmentRate':
      case 'treatAttachmentRate':
      case 'cornAttachmentRate':
      case 'waterAttachmentRate':
      case 'saucesAttachmentRate':
      case 'sticksAttachmentRate':
      case 'dessertsAttachmentRate':
      case 'beveragesAttachmentRate':
      case 'jalapenoAttachmentRate':
      case 'dessertRate':
      case 'comboRate':
      case 'drinkRate':
      case 'appetizerPpaV2':
      case 'lbwPpaV2':
      case 'dessertPpaV2':
      case 'naBeveragePpa':
      case 'sidesPpa':
        return LIST_LOCATION_SALESMANSHIP_KPIS
      case 'sosPickup':
        return LIST_LOCATION_SPEED_OF_SERVICE
      case 'entreesPerLaborHour':
        return LIST_ENTREES_PER_LABOR_HOUR
      case 'upsizePercent':
        return LIST_UPSIZE_PERCENT
      case 'zenput':
        return LIST_ZENPUT_SCORE
      case 'cavayou':
        return LIST_CAVAYOU_SCORE
      case 'acr_score':
        return LIST_LOCATION_ACR_SCORE_RANKINGS
      case 'inboundCalls':
        return LIST_LOCATION_INBOUND_CALLS
      case 'voids':
      case 'discounts':
      case 'cashOverOrShort':
        return LIST_LOCATION_LOSS_PREVENTION_KPIS
      case 'smg':
        return LIST_LOCATION_SMG_KPIS
      case 'marketingAnalysis':
        return RANK_LOCATION_DISCOUNT_CAMPAIGN
      case 'hme':
        return LIST_HME
      case 'avgDeliveryTimeDoordash':
        return METRIC_VALUES
      default:
        return RANK_LOCATION_SCORECARD
    }
  }
}
