import { Chart } from 'chart.js'
import { useMemo } from 'react'

import COLORS from 'pared/constants/colors'
import { TYPE_PERIOD, TYPE_QUARTER, TYPE_YEAR } from 'pared/data/getDateRanges'
import { getBrand } from 'pared/utils/brand'

import { IGetProductHistoricalPerformanceListMenuItemCostingNodesType } from '../gqls/historicalPerformance'
import { getNumber, getPrice } from '../utils'

const useHistoricalPerformanceChart = (
  title: string,
  data: IGetProductHistoricalPerformanceListMenuItemCostingNodesType[],
  dateType: typeof TYPE_YEAR | typeof TYPE_QUARTER | typeof TYPE_PERIOD,
) => {
  const brand = getBrand()
  const isSalesMix = ['mwb', 'burgerworks'].includes(brand)
  return useMemo(
    () => ({
      type: 'line' as const,
      data: {
        labels: data?.map(
          ({ businessYear, businessQuarter, businessMonth }) =>
            ({
              [TYPE_YEAR]: `Y${businessYear}`,
              [TYPE_QUARTER]: `Q${businessQuarter} ${businessYear}`,
              [TYPE_PERIOD]: `P${businessMonth} ${businessYear}`,
            }[dateType] ?? `P${businessMonth} ${businessYear}`),
        ),
        datasets: [
          {
            label: 'Incidents per 100',
            data: data?.map(({ popularity }) => popularity),
            borderColor: COLORS.STACKED_BAR_COLOR_HUE[0],
            backgroundColor: COLORS.STACKED_BAR_COLOR_HUE[0],
          },
          ...(isSalesMix
            ? []
            : [
                {
                  type: 'bar' as const,
                  yAxisID: 'yRight',
                  label: 'Portion CM ($)',
                  data: data?.map(({ portionContributionMargin }) =>
                    getNumber(portionContributionMargin),
                  ),
                  borderColor: COLORS.STACKED_BAR_COLOR_HUE[1],
                  backgroundColor: COLORS.STACKED_BAR_COLOR_HUE[1],
                },
              ]),
          {
            type: 'bar' as const,
            yAxisID: 'yRight',
            label: 'Average Selling Price ($)',
            data: data?.map(({ price }) => getNumber(price)),
            borderColor: COLORS.STACKED_BAR_COLOR_HUE[2],
            backgroundColor: COLORS.STACKED_BAR_COLOR_HUE[2],
          },
        ],
      },
      options: {
        plugins: {
          title: {
            display: true,
            text: title,
          },
          tooltip: {
            mode: 'index' as const,
            callbacks: {
              label: ({
                dataset: { label },
                chart,
                datasetIndex,
                dataIndex,
                formattedValue,
              }: {
                dataset: { label?: string }
                chart: Chart
                datasetIndex: number
                dataIndex: number
                formattedValue: string
              }) => {
                const { datasets } = chart.data

                if (datasetIndex === 1) {
                  const portionContributionMargin =
                    datasets[datasetIndex].data[dataIndex]
                  const price = datasets[datasetIndex + 1]?.data[dataIndex]

                  if (
                    typeof portionContributionMargin === 'number' &&
                    typeof price === 'number'
                  ) {
                    const percentage = (
                      (portionContributionMargin / price) *
                      100
                    ).toFixed(2)

                    return `${label}: ${getPrice(
                      portionContributionMargin,
                    )} (${percentage}%)`
                  }
                }

                if (datasetIndex === 2 || (isSalesMix && datasetIndex === 1)) {
                  const price = datasets[datasetIndex].data[dataIndex]

                  if (typeof price === 'number')
                    return `${label}: ${getPrice(price)}`
                }

                return `${label}: ${formattedValue}`
              },
            },
          },
        },
        scales: {
          x: {
            stacked: true,
          },
          y: {
            title: {
              text: 'Incidents per 100',
            },
            grid: {
              display: false,
            },
          },
          yRight: {
            position: 'right' as const,
            beginAtZero: false,
            ticks: {
              callback: getPrice,
            },
          },
        },
      },
    }),
    [title, data, dateType],
  )
}

export default useHistoricalPerformanceChart
