import { useMemo } from 'react'

import { getBrand } from 'pared/utils/brand'

interface IConfig {
  matrix?: {
    hideTitle?: boolean
    isSalesMix?: boolean
  }
}

const defaultConfig = {}

export default (): IConfig => {
  const brand = getBrand()

  return useMemo(() => {
    switch (brand) {
      case 'mwb':
      case 'burgerworks':
        return {
          ...defaultConfig,
          matrix: {
            hideTitle: true,
            isSalesMix: true,
          },
        }
      default:
        return defaultConfig
    }
  }, [brand])
}
