import { gql, useQuery } from '@apollo/client'
import * as d3 from 'd3-scale'
import * as _ from 'lodash'
import moment from 'moment'
import { useMemo } from 'react'

import { useDateFilter } from '../../../dateFilter'
import { useGroupFilter } from '../../../groupFilter'
import { corporateGroupTableConfigs } from '../../../variables'
import { useVariables } from '../../../variables'
import { IApiDataType } from '../../types'

type IMetricDataType = Record<
  string,
  {
    name: string
    unit: 'CENT' | 'PERCENTAGE' | 'DOLLAR' | 'COUNT' | 'SECONDS' | 'SCORE'
    value: number
  }
>

interface ITrendLocationGroupMetricValuesByDateRangeNumberType {
  locationGroupId: number
  businessYear: number
  businessQuarter: number
  businessMonth: number
  businessWeek: number
  businessWeekOfMonth: number
  dateIndex: number
  metricData: IMetricDataType
  metricSummaryData: IMetricDataType | null
}

interface ITrendLocationMetricValuesByDateRangeNumberType {
  locationId: number
  businessYear: number
  businessQuarter: number
  businessMonth: number
  businessWeek: number
  businessWeekOfMonth: number
  dateIndex: number
  metricData: IMetricDataType
  metricSummaryData: IMetricDataType | null
}

interface IListLocationMarketAndOpenDateType {
  locationId: number
  openedAt: string
  market: string
}

interface IDataType {
  trendLocationGroupMetricValuesByDateRangeNumber: {
    nodes: ITrendLocationGroupMetricValuesByDateRangeNumberType[]
  }

  trendLocationMetricValuesByDateRangeNumber: {
    nodes: ITrendLocationMetricValuesByDateRangeNumberType[]
  }

  listLocationMarketAndOpenDate: {
    nodes: IListLocationMarketAndOpenDateType[]
  }
}

const query = gql`
  query ListFarwestDiscountAndVoid(
    $iEndDate: Date!
    $iGroupBy: String!
    $iFilter: JSON!
    $hasGroupBy: Boolean!
  ) {
    trendLocationGroupMetricValuesByDateRangeNumber(
      iEndDate: $iEndDate
      iGroupBy: $iGroupBy
      iFilter: $iFilter
      iDateRangeNumber: 4
    ) @skip(if: $hasGroupBy) {
      nodes {
        locationGroupId
        businessYear
        businessQuarter
        businessMonth
        businessWeek
        businessWeekOfMonth
        dateIndex
        metricData
        metricSummaryData
      }
    }

    trendLocationMetricValuesByDateRangeNumber(
      iEndDate: $iEndDate
      iGroupBy: $iGroupBy
      iFilter: $iFilter
      iDateRangeNumber: 4
    ) @include(if: $hasGroupBy) {
      nodes {
        locationId
        businessYear
        businessQuarter
        businessMonth
        businessWeek
        businessWeekOfMonth
        dateIndex
        metricData
        metricSummaryData
      }
    }

    listLocationMarketAndOpenDate(
      iStartDate: $iEndDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) @include(if: $hasGroupBy) {
      nodes {
        locationId
        openedAt
        market
      }
    }
  }
`

export const fwWingstopDiscountAndVoidConfigs = {
  '<%- JSON(corporateGroup?.tableColumns.slice(0, 1)) %>':
    corporateGroupTableConfigs['<%- JSON(corporateGroup?.tableColumns) %>'],
  '<%- JSON(corporateGroup?.tableColumns.slice(1, 2)) %>':
    corporateGroupTableConfigs['<%- JSON(corporateGroup?.tableColumns) %>'],
  market: 'string',

  //flash_discounts_and_voids
  discountPercentageDateRange1InPast: 'percent',
  discountPercentageDateRange2InPast: 'percent',
  discountPercentageDateRange3InPast: 'percent',
  discountPercentageDateRange4InPast: 'percent',

  voidPercentageDateRange1InPast: 'percent',
  voidPercentageDateRange2InPast: 'percent',
  voidPercentageDateRange3InPast: 'percent',
  voidPercentageDateRange4InPast: 'percent',

  refundPercentageDateRange1InPast: 'percent',
  refundPercentageDateRange2InPast: 'percent',
  refundPercentageDateRange3InPast: 'percent',
  refundPercentageDateRange4InPast: 'percent',
} as const

const KPIS = [
  //flash_discounts_and_voids
  'discount_percentage',
  'void_percentage',
  'refund_percentage',
]

function heatmapMapping(value: number, min: number, max: number) {
  const x = d3.scaleLinear([0, 100], [min, max])

  return _.isNil(value) ? null : x.invert(value)
}

const useFwWingstopDiscountAndVoid = () => {
  const { endDate } = useDateFilter()
  const { groupFilter, hasGroupBy } = useGroupFilter()
  const { variables } = useVariables()
  const corporateDetails = useMemo(() => {
    if (!variables.corporateGroup) return

    if (
      'locations' in variables.corporateGroup &&
      variables.corporateGroup.locations
    )
      return variables.corporateGroup.locations

    if (
      'locationGroups' in variables.corporateGroup &&
      variables.corporateGroup.locationGroups
    )
      return variables.corporateGroup.locationGroups
  }, [variables])

  const yesterday = moment().subtract(1, 'day')
  const adjustedEndDate =
    endDate && moment(endDate, 'YYYY-MM-DD').isAfter(yesterday)
      ? yesterday.format('YYYY-MM-DD')
      : endDate

  const dateType = variables.date?.value.type
  const iGroupBy = ((dateType?: string) => {
    switch (dateType) {
      case 'week':
      case 'this_week':
        return 'last_x_weeks'

      case 'period':
        return 'last_x_periods'

      case 'quarter':
        return 'last_x_quarters'

      case 'year':
        return 'last_x_years'

      default:
        return 'last_x_days'
    }
  })(dateType)

  const { data, loading } = useQuery<IDataType>(query, {
    variables: {
      iEndDate: adjustedEndDate,
      iGroupBy,
      iFilter: {
        location_group_ids: hasGroupBy
          ? groupFilter?.ids
          : groupFilter?.list?.map((g) => g.id),
        intersected_location_group_ids: groupFilter?.intersectedIds,
        metrics: KPIS,
      },
      hasGroupBy,
    },
    skip: !adjustedEndDate || !groupFilter || !iGroupBy,
  })

  return {
    data: useMemo((): IApiDataType => {
      const customizedData =
        data?.[
          hasGroupBy
            ? 'trendLocationMetricValuesByDateRangeNumber'
            : 'trendLocationGroupMetricValuesByDateRangeNumber'
        ]?.nodes
      const locationDetails: any = data?.listLocationMarketAndOpenDate?.nodes

      if (!customizedData) return null

      const idToSourceDataMap: { [id: number]: any } = {}
      const idToSourceHeatmap: { [key: string]: number } = {}
      const summary: { [key: string]: number | null } = {}

      customizedData.forEach((locationData: any) => {
        const groupInfo = (() => {
          if (hasGroupBy && 'locationId' in locationData) {
            return groupFilter?.list?.find(
              (l) => l.id === locationData.locationId,
            )
          } else if (!hasGroupBy && 'locationGroupId' in locationData) {
            return groupFilter?.list?.find(
              (l) => l.id === locationData.locationGroupId,
            )
          } else {
            return {
              id: null,
            }
          }
        })()

        if (!groupInfo?.id || !corporateDetails) {
          return
        }

        let existingData: any = idToSourceDataMap[groupInfo.id]
        if (!existingData) {
          const marketDetail = locationDetails?.find(
            (l: any) => l.locationId === locationData.locationId,
          )

          existingData = {
            groupInfo,
            openAt: marketDetail?.openedAt,
            market: marketDetail?.market,
            ...Object.values(corporateDetails)?.find(
              (cd) => cd.id === groupInfo.id,
            )?.tableRow,
          }
        }

        if (!existingData) return

        for (const key in locationData.metricData) {
          if (locationData.metricData.hasOwnProperty(key)) {
            let newKey = key.replace(/_(.)/g, (_, char) => char.toUpperCase())
            newKey = `${newKey}DateRange${locationData.dateIndex}InPast`

            switch (locationData.metricData[key].unit) {
              case 'DOLLAR':
              case 'PERCENTAGE':
                existingData[newKey] = _.isNil(
                  locationData.metricData[key]?.value,
                )
                  ? null
                  : locationData.metricData[key].value * 100

                if (_.isNil(idToSourceHeatmap[`${newKey}Max`])) {
                  idToSourceHeatmap[`${newKey}Max`] =
                    locationData.metricData[key]?.value * 100
                } else {
                  if (
                    idToSourceHeatmap[`${newKey}Max`] <
                    locationData.metricData[key]?.value * 100
                  ) {
                    idToSourceHeatmap[`${newKey}Max`] =
                      locationData.metricData[key]?.value * 100
                  }
                }

                if (_.isNil(idToSourceHeatmap[`${newKey}Min`])) {
                  idToSourceHeatmap[`${newKey}Min`] =
                    locationData.metricData[key]?.value * 100
                } else {
                  if (
                    idToSourceHeatmap[`${newKey}Min`] &&
                    idToSourceHeatmap[`${newKey}Min`] >
                      locationData.metricData[key]?.value * 100
                  ) {
                    idToSourceHeatmap[`${newKey}Min`] =
                      locationData.metricData[key]?.value * 100
                  }
                }

                break

              default:
                existingData[newKey] = _.isNil(
                  locationData.metricData[key]?.value,
                )
                  ? null
                  : locationData.metricData[key].value

                if (_.isNil(idToSourceHeatmap[`${newKey}Max`])) {
                  idToSourceHeatmap[`${newKey}Max`] =
                    locationData.metricData[key]?.value
                } else {
                  if (
                    idToSourceHeatmap[`${newKey}Max`] <
                    locationData.metricData[key]?.value
                  ) {
                    idToSourceHeatmap[`${newKey}Max`] =
                      locationData.metricData[key]?.value
                  }
                }

                if (_.isNil(idToSourceHeatmap[`${newKey}Min`])) {
                  idToSourceHeatmap[`${newKey}Min`] =
                    locationData.metricData[key]?.value
                } else {
                  if (
                    idToSourceHeatmap[`${newKey}Min`] &&
                    idToSourceHeatmap[`${newKey}Min`] >
                      locationData.metricData[key]?.value * 100
                  ) {
                    idToSourceHeatmap[`${newKey}Min`] =
                      locationData.metricData[key]?.value
                  }
                }
                break
            }
          }
        }

        if (locationData.metricSummaryData) {
          const metricSummaryData = locationData.metricSummaryData

          for (const key in metricSummaryData) {
            if (metricSummaryData.hasOwnProperty(key)) {
              let newKey = key.replace(/_(.)/g, (_, char) => char.toUpperCase())
              newKey = `${newKey}DateRange${locationData.dateIndex}InPast`

              if (_.isNil(metricSummaryData[key].value)) {
                summary[newKey] = null
                continue
              }

              switch (metricSummaryData[key].unit) {
                case 'DOLLAR':
                case 'PERCENTAGE':
                  summary[newKey] = metricSummaryData[key].value * 100
                  break

                default:
                  summary[newKey] = metricSummaryData[key].value
                  break
              }
            }
          }
        }

        idToSourceDataMap[groupInfo.id] = existingData
      })

      const sourceData: IApiDataType = Object.values(idToSourceDataMap).map(
        (sd) => {
          const heatmap: { [key: string]: number | null } = {}

          for (const key of Object.keys(sd)) {
            if (key.includes('InPast'))
              heatmap[`${key}HeatMap`] = heatmapMapping(
                sd[key],
                idToSourceHeatmap[`${key}Min`],
                idToSourceHeatmap[`${key}Max`],
              )
          }
          return {
            ...sd,
            ...heatmap,
            id: sd.groupInfo.id.toString(),
            parentId: 'summary',
          }
        },
      )
      return [
        ...sourceData,
        {
          ...summary,
          id: 'summary',
          parentId: 'root',
          openAt: '',
          market: '',
          locationGroup: {
            label: 'Total',
          },
        },
      ]
    }, [groupFilter, data, variables]),
    loading,
  }
}

export default useFwWingstopDiscountAndVoid
