import { IConfigsType } from '../../types'

const bibibopExpense: IConfigsType = {
  'bibibop-expenses-f3e8535d': {
    '/:brand/expenses': {
      dateFilter: {
        types: [
          'week',
          'period',
          'quarter',
          'year',
          'last_week',
          'this_week',
          'yesterday',
          'custom',
        ],
        isDailyCustom: true,
      },
      expenses: {
        type: 'table',
        title: 'Expenses',
        api: 'bbbExpenses',
        pageSize: 20,
        defaultSortedField: 'date',
        columns: [
          {
            key: 'date',
            title: 'Date',
            align: 'center',
            sortable: true,
            format: 'M/D/YY',
            timezone: 0,
          },
          {
            key: 'location',
            title: 'Location',
            align: 'center',
            sortable: true,
          },
          {
            key: 'employee',
            title: 'Employee',
            align: 'center',
            sortable: true,
          },
          {
            key: 'transactionAmount',
            title: 'Transaction Amount',
            align: 'center',
            sortable: true,
            decimal: 2,
          },
          {
            key: 'expenseType',
            title: 'Expense Type',
            align: 'center',
            sortable: true,
          },
          {
            key: 'expenseName',
            title: 'Expense Name',
            align: 'center',
            sortable: true,
          },
          {
            key: 'vendor',
            title: 'Vendor',
            align: 'center',
            sortable: true,
          },
          {
            key: 'businessPurpose',
            title: 'Business Purpose',
            align: 'center',
            sortable: true,
          },
        ],
      },
    },
  },
}

export default bibibopExpense
