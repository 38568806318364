import { IRouteType } from '../../types'
import storeHome from './storeHome'
import wingitnorthDailyFlash from './wingitnorthDailyFlash'
import wingitnorthPAndL from './wingitnorthPAndL'
import wingitnorthPAndLConfig from './wingitnorthPAndLConfig'
import wingitnorthSystemwideOverview from './wingitnorthSystemwideOverview'

export const routes: IRouteType[] = [
  {
    title: 'Corporate',
    children: [
      {
        title: 'Systemwide Overview',
        link: '/:brand/systemwide_overview',
        default: true,
      },
      {
        title: 'Daily Flash',
        link: '/:brand/daily_flash',
        default: true,
      },
    ],
  },
  {
    title: 'Store',
    children: [
      {
        title: 'Home',
        link: '/:brand/scorecard',
      },
    ],
  },
  {
    title: 'P&L',
    children: [
      {
        title: 'Expo AI P&L',
        link: '/:brand/pnl',
      },
      {
        title: 'Config',
        link: '/:brand/pnl-config',
        hideFromNavMenu: true,
      },
    ],
  },
]

export default [
  storeHome,
  wingitnorthDailyFlash,
  wingitnorthSystemwideOverview,
  wingitnorthPAndL,
  wingitnorthPAndLConfig,
]
