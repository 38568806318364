import {
  Chart as BaseChart,
  ChartType as BaseChartType,
  ChartDataset,
} from 'chart.js'
import * as d3 from 'd3-scale'
import { schemeCategory10 } from 'd3-scale-chromatic'
import * as _ from 'lodash'
import { useMemo } from 'react'
import styled from 'styled-components'

import ChartComponent, { IPropsType as IChartsPropsType } from 'pared/charts'
import Spin from 'pared/components/basicUi/spin'
import COLORS from 'pared/constants/colors'
import { toPercentString, toUsdString } from 'pared/utils/number'

import { useVariables } from '../variables'
import useApi, { IApiKeyType, configs } from './hooks/useApi'

type IFormatType = 'string' | 'number' | 'percent' | 'price'

interface IBaseDatasetType {
  value?: unknown
  beginAtZero?: boolean
  min?: number

  // for bubble chart
  xType?: IFormatType
  xDecimal?: number
  yType?: IFormatType
  yDecimal?: number
}

type IDatasetType =
  | (IBaseDatasetType & {
      type: 'price'
      decimal?: number
    })
  | (IBaseDatasetType & {
      type: 'string'
    })
  | (IBaseDatasetType & {
      type: 'percent'
      decimal?: number
    })
  | (IBaseDatasetType & {
      type: 'number'
      decimal?: number
    })

type IBubbleDatasetType = IBaseDatasetType & {
  label: string
  data: {
    x: number
    y: number
    r: number
    label: string
  }[]
}

type IMixedDatasetType<
  V extends Record<string, Pick<IDatasetType, 'type'>['type']>,
> = {
  [K in keyof V]: Omit<
    Extract<
      IDatasetType,
      {
        type: V[K]
      }
    >,
    'type' | 'value'
  > & {
    key: K
    label?: string
    chartType?: BaseChartType
    xAxisID?: string
    yAxisID?: string
    borderColor?: string
    backgroundColor?: string | string[]
    hoverOffset?: number
    order?: number
  }
}

type IDatasetsType = {
  [K in keyof typeof configs]: IMixedDatasetType<typeof configs[K]>
}

export interface IPropsType<K extends IApiKeyType = IApiKeyType>
  extends Omit<IChartsPropsType, 'type' | 'data'> {
  type: `${IChartsPropsType['type']}-chart`
  api: K
  label: keyof typeof configs[K]
  datasets: IDatasetsType[K][keyof IDatasetsType[K]][]
  scaleTypes: Record<string, IDatasetType>
  margin?: string

  // for bubble chart
  datasetConfig?: {
    x: string
    xType: IFormatType
    xDecimal: number
    y: string
    yType: IFormatType
    yDecimal: number
    r: string
    label: string
  }
}

export type IConfigsType = {
  [K in IApiKeyType]: IPropsType<K>
}[IApiKeyType]

const format = ({ value, ...data }: IDatasetType): string => {
  // for bubble chart
  if (
    value &&
    typeof value === 'object' &&
    'x' in value &&
    'y' in value &&
    data.xType &&
    data.yType
  ) {
    return `(${format({
      value: value.x,
      type: data.xType,
      decimal: data.xDecimal,
    })}, ${format({
      value: value.y,
      type: data.yType,
      decimal: data.yDecimal,
    })})`
  }

  switch (data.type) {
    case 'price':
      return toUsdString(parseFloat(value as string) / 100, data.decimal)
    case 'percent':
      return toPercentString(parseFloat(value as string), data.decimal)
    case 'number':
      return parseFloat(value as string).toLocaleString('en-US', {
        minimumFractionDigits: data.decimal || 0,
        maximumFractionDigits: data.decimal || 0,
      })
    default:
      return ''
  }
}

const Root = styled.div<{ margin: string }>`
  margin: ${({ margin }) => margin};
`

const useDatasets = <K extends IApiKeyType>(
  datasets: IDatasetsType[K][keyof IDatasetsType[K]][],
) => {
  const { template } = useVariables()

  return useMemo(
    () =>
      datasets.reduce((result, dataset) => {
        const key: IDatasetsType[K][keyof IDatasetsType[K]][] =
          template(dataset.key as string) || ''

        if (key instanceof Array)
          return [
            ...result,
            ...key.map((k) => ({
              ...dataset,
              ...k,
              configKey: dataset.key,
            })),
          ]

        return [...result, dataset]
      }, [] as IDatasetsType[K][keyof IDatasetsType[K]][]),
    [datasets, template],
  )
}

const Chart = ({
  type,
  api,
  label,
  datasets: originDatasets,
  datasetConfig,
  scaleTypes,
  margin = '20px 0px 0px',
  ...props
}: IPropsType) => {
  const { data, loading } = useApi(api)
  let datasets: any[] = useDatasets(originDatasets)
  const chartConfigs = useMemo(() => {
    const config = configs[api]
    const maxLabelAmount = [...(data || [])]
      .reverse()
      .findIndex((d) =>
        datasets.some(
          ({ key }) =>
            ![null, undefined].includes(_.get(d, key) as null | undefined),
        ),
      )
    const filteredData = data?.slice(0, data.length - maxLabelAmount)

    if (type === 'bubbleWithLabels-chart' && datasetConfig) {
      const scale = data?.map((d) => d[datasetConfig.r] as number) ?? []
      const min = Math.min(...scale)
      const max = Math.max(...scale)
      const scaler = d3.scaleLinear().domain([min, max]).range([3, 25])
      const colors = d3.scaleOrdinal(schemeCategory10)

      const { x, xType, xDecimal, y, yType, yDecimal, r, label } = datasetConfig

      datasets = (data ?? []).reduce((result, d) => {
        const category =
          d.displayParentCategoryName === 'All Menu Items' ||
          d.displayParentCategoryName === null
            ? 'Uncategorized'
            : (d.displayParentCategoryName as string)
        const dataset = result.find(({ label }) => label === category)
        const data = {
          x: d[x] as number,
          y: d[y] as number,
          r: scaler(d[r] as number),
          label: d[label] as string,
        }

        if (!dataset) {
          return [
            ...result,
            {
              label: category,
              data: [data],
              backgroundColor: colors(category as string),
              xKey: x,
              yKey: y,
              xDecimal,
              yDecimal,
              xType,
              yType,
            },
          ]
        }

        dataset.data.push(data)

        return result
      }, [] as IBubbleDatasetType[])
    }

    return {
      data: {
        labels: filteredData?.map((d) => _.get(d, label)),
        datasets:
          type === 'bubbleWithLabels-chart'
            ? datasets
            : (datasets.map(
                (
                  {
                    key,
                    borderColor,
                    backgroundColor,
                    chartType,
                    ...dataset
                  }: {
                    key: keyof typeof config
                    chartType?: BaseChartType
                  } & Pick<ChartDataset, 'borderColor' | 'backgroundColor'>,
                  index,
                ) => ({
                  ...dataset,
                  type: chartType,
                  data: filteredData?.map((d) => _.get(d, key)) || [],
                  borderColor:
                    borderColor || COLORS.STACKED_BAR_COLOR_HUE[index],
                  backgroundColor:
                    backgroundColor || COLORS.STACKED_BAR_COLOR_HUE[index],
                }),
              ) as ChartDataset[]),
      },
      options: {
        plugins: {
          tooltip: {
            callbacks: {
              label: ({
                dataset: { label },
                chart,
                datasetIndex,
                dataIndex,
                formattedValue,
              }: {
                dataset: { label?: string }
                chart: BaseChart
                datasetIndex: number
                dataIndex: number
                formattedValue: string
              }) => {
                const dataset = datasets[datasetIndex] as IDatasetType & {
                  configKey?: keyof typeof config
                  key: keyof typeof config
                }
                const value = chart.data.datasets[datasetIndex].data[dataIndex]

                return `${
                  (label && _.get(filteredData?.[dataIndex], label)) ||
                  (type === 'bubbleWithLabels-chart' &&
                  value &&
                  typeof value === 'object' &&
                  'label' in value
                    ? value.label
                    : label)
                }: ${
                  format({
                    ...dataset,
                    type: config[dataset.configKey || dataset.key],
                    value,
                  }) || formattedValue
                }`
              },
            },
            itemSort: (
              a: { datasetIndex: number },
              b: { datasetIndex: number },
            ) => a.datasetIndex - b.datasetIndex,
          },
          legend: {
            labels: {
              sort: (
                a: { datasetIndex: number },
                b: { datasetIndex: number },
              ) => a.datasetIndex - b.datasetIndex,
            },
          },
        },
        scales:
          scaleTypes &&
          Object.keys(scaleTypes).reduce(
            (result, key) => ({
              ...result,
              [key]: {
                ticks: {
                  callback: (value: unknown) =>
                    format({
                      ...scaleTypes[key],
                      value,
                    }),
                },
                beginAtZero: scaleTypes[key].beginAtZero ?? false,
                min: scaleTypes[key].min ?? null,
              },
            }),
            {},
          ),
      },
    }
  }, [configs, api, data, label, datasets, scaleTypes])

  return (
    <Root margin={margin}>
      <Spin spinning={loading}>
        <ChartComponent
          {..._.merge({}, chartConfigs, props)}
          type={type.replace(/-chart/, '') as IChartsPropsType['type']}
        />
      </Spin>
    </Root>
  )
}

export default Chart
