import CloseIcon from '@mui/icons-material/Close'
import { useState } from 'react'
import ReactDOM from 'react-dom'
import styled from 'styled-components'

import ExpoLogo from 'pared/images/Expo/expo-symbol-white-1x.svg'
import { getBrand } from 'pared/utils/brand'

export interface IPropsType {
  type: 'chatbox-v2'
  width?: string
  height?: string
}

const ChatContainer = styled.div`
  position: fixed;
  bottom: 0px;
  right: 40px;
`

const ChatButton = styled.button`
  background-color: rgb(59, 59, 59);
  color: #fff;
  border: none;
  font-family: Lexend-SemiBold;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  cursor: pointer;
  display: flex;
  align-items: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 10px 10px 0 0;
`

const ChatButtonTitle = styled.div`
  margin-right: 2px;
`

const ChatWindow = styled.div<{
  isOpen: boolean
  width?: string
  height?: string
}>`
  width: ${(props) => (props.width ? props.width : '500px')};
  height: ${(props) => (props.isOpen ? props.height || '800px' : '0px')};
  background-color: #fff;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  border-radius: 10px 10px 0 0;
  overflow: hidden;
  transition: height 0.3s ease-in-out;
`

const ChatHeader = styled.div`
  background-color: rgb(59, 59, 59);
  color: #fff;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const HeaderTitle = styled.div`
  display: flex;
  align-items: center;
  font-family: Lexend-SemiBold;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
`

const ChatIcon = styled.img`
  width: 30px;
  height: 30px;
  margin: 4px 8px 4px 2px;
`

const StyledIconButton = styled(CloseIcon)`
  color: #fff;
`

const ChatIframe = styled.iframe`
  width: 100%;
  height: 95%;
  border: none;
`

const ChatButtonContainer = styled.div<{
  isOpen: boolean
}>`
  display: ${(props) => (!props.isOpen ? 'flex' : 'none')};
  justify-content: space-between;
`

const ChatComponent = ({ width, height }: IPropsType) => {
  const [isOpen, setIsOpen] = useState(false)
  const brandCode = getBrand()
  const expoSousChefUrl = process.env.REACT_APP_EXPO_SOUS_CHEF_URL
  const url = `${expoSousChefUrl}/${brandCode}`

  const chatContent = (
    <ChatContainer>
      <ChatButtonContainer isOpen={isOpen}>
        <div></div>
        <ChatButton onClick={() => setIsOpen(!isOpen)}>
          <ChatIcon src={ExpoLogo} alt="Expo Logo Icon" />
          <ChatButtonTitle>Ask Expo</ChatButtonTitle>
        </ChatButton>
      </ChatButtonContainer>

      <ChatWindow isOpen={isOpen} width={width} height={height}>
        <ChatHeader onClick={() => setIsOpen(false)}>
          <HeaderTitle>
            <ChatIcon src={ExpoLogo} alt="Expo Logo Icon" />
            Ask Expo
          </HeaderTitle>
          <StyledIconButton onClick={() => setIsOpen(false)}>
            <CloseIcon />
          </StyledIconButton>
        </ChatHeader>
        <ChatIframe src={url} title="Expo Sous-Chef" allow="microphone" />
      </ChatWindow>
    </ChatContainer>
  )

  return ReactDOM.createPortal(chatContent, document.body)
}

export default ChatComponent
