import { IRouteType } from '../../types'
import mwbHome from './mwbHome'
import mwbPAndL from './mwbPAndL'
import mwbProduct from './mwbProduct'
import mwbSystemwideOverview from './mwbSystemwideOverview'
import mwbOriginalCorporatePagesConfig from './originalCorporatePages'

export const routes: IRouteType[] = [
  {
    title: 'Corporate',
    children: [
      {
        title: 'Systemwide Overview',
        link: '/:brand/systemwide_overview',
        default: true,
      },
      {
        title: 'Product',
        collapsible: true,
        children: [
          {
            title: 'Sales Mix',
            link: '/:brand/corporate_product',
            useOriginal: true,
          },
          {
            title: 'Item by Daypart',
            link: '/:brand/item_by_daypart',
          },
          {
            title: 'Item Velocity',
            link: '/:brand/item_velocity',
          },
          {
            title: 'Trending',
            link: '/:brand/product_trending',
          },
          {
            title: 'Product Insight',
            link: '/:brand/product_insight',
          },
          {
            title: 'Product Insight QA',
            link: '/:brand/product_insight_qa',
          },
        ],
      },
    ],
  },
  {
    title: 'Store',
    children: [
      {
        title: 'Home',
        link: '/:brand/scorecard',
      },
    ],
  },
  {
    title: 'P&L',
    children: [
      {
        title: 'Expo AI P&L',
        link: '/:brand/pnl',
      },
    ],
  },
]

export default [
  mwbSystemwideOverview,
  mwbProduct,
  mwbHome,
  mwbPAndL,
  mwbOriginalCorporatePagesConfig,
]
