import * as d3 from 'd3-scale'
import { schemeCategory10 } from 'd3-scale-chromatic'
import { useMemo } from 'react'

import { getBrand } from 'pared/utils/brand'

import { IPerformanceChartFragmentType } from '../gqls/performanceChart'
import { getNumber, getPrice, getTotalPrice } from '../utils'

interface IDatasetType {
  label: string
  data: {
    x: number
    y: number
    r: number
    label: string
  }[]
  backgroundColor: string
}

const usePerformanceChartConfig = (data: IPerformanceChartFragmentType[]) => {
  const brand = getBrand()
  const isSalesMix = ['mwb', 'burgerworks'].includes(brand)
  return useMemo(() => {
    const popularities = data.map(({ popularity }) => popularity)
    const min = Math.min(...popularities)
    const max = Math.max(...popularities)
    const scaler = d3.scaleLinear().domain([min, max]).range([3, 25])
    const colors = d3.scaleOrdinal(schemeCategory10)
    const datasets = data.reduce(
      (
        result,
        {
          popularity,
          portionContributionMargin,
          totalContributionMargin,
          itemName,
          category,
          theoreticalCostPerUnit,
          itemSales,
        },
      ) => {
        const dataset = result.find(({ label }) => label === category)
        const data = {
          x: getNumber(isSalesMix ? itemSales : totalContributionMargin),
          y: getNumber(
            isSalesMix ? theoreticalCostPerUnit : portionContributionMargin,
          ),
          r: scaler(popularity),
          label: itemName,
        }

        if (!dataset) {
          return [
            ...result,
            {
              label: category,
              data: [data],
              backgroundColor: colors(category),
            },
          ]
        }

        dataset.data.push(data)

        return result
      },
      [] as IDatasetType[],
    )

    return {
      type: 'bubbleWithLabels' as const,
      data: { datasets },
      options: {
        plugins: {
          tooltip: {
            callbacks: {
              label: ({ raw }: { raw: unknown }) => {
                const { x, y, label } = raw as IDatasetType['data'][number]

                return ` ${label} (${getTotalPrice(x)}, ${getPrice(y)})`
              },
            },
          },
        },
        scales: {
          x: {
            title: {
              text: isSalesMix
                ? 'Total Sales'
                : 'Total Contribution Margin ($)',
            },
            ticks: {
              callback: getTotalPrice,
            },
          },
          y: {
            title: {
              text: isSalesMix
                ? 'Selling Price'
                : 'Portion Contribution Margin ($)',
            },
            ticks: {
              callback: getPrice,
            },
          },
        },
      },
    }
  }, [data])
}

export default usePerformanceChartConfig
