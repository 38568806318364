import { IConfigsType } from '../../types'

const fwWingstopExpoSousChef: IConfigsType = {
  fw_wingstop: {
    '/:brand/expo_sous_chef': {
      title: {
        type: 'title',
        title: 'Expo Sous-Chef',
      },
      chatbox: {
        type: 'chatbox-v2',
      },
    },
  },
}

export default fwWingstopExpoSousChef
