import { useMemo } from 'react'

import { IApiDataType, ISelectDataType } from '../types'
import useCorporateGroupFilter from './useCorporateFilter'

const useFwWingstopCorporateFilter = () => {
  const { data, loading } = useCorporateGroupFilter()

  return {
    data: useMemo((): IApiDataType => {
      if (!data) return null

      const { defaultValue, values } = data

      const getNewItems = (v: ISelectDataType) =>
        ['Core or Non-Core', 'Comp or Non-Comp']
          .map((type) => {
            const item = values.find((v) => v.id === `${type} Breakdown`)

            if (!item) return []

            return [
              ...(/Breakdown/.test(v.id)
                ? []
                : [
                    {
                      ...item,
                      id: `${v.id} ${item.id}`,
                      parentId: `${v.id} root`,
                      intersectedIds: 'ids' in v ? v.ids : undefined,
                    },
                  ]),
              ...(!('list' in item)
                ? []
                : (item.list || []).map((i) => ({
                    ...v,
                    id: `${v.id} ${i.name}`,
                    parentId: `${v.id} root`,
                    label: i.name,
                    intersectedIds: [i.id],
                  }))),
            ]
          })
          .flat()

      return {
        defaultValue,
        values: values
          .map((v) => {
            if (v.parentId !== 'Market' && v.id !== 'Market Breakdown') return v

            const newItems = getNewItems(v)

            if (newItems.length === 0) return v

            return [
              { id: `${v.id} root`, parentId: v.parentId, label: v.label },
              {
                ...v,
                parentId: `${v.id} root`,
                label: /Breakdown/.test(v.id) ? 'All Markets' : 'All Stores',
              },
              ...newItems,
            ]
          })
          .flat(),
      }
    }, [data]),
    loading,
  }
}

export default useFwWingstopCorporateFilter
